import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { FormDetailViewModel } from 'src/app/form-listing/form-detail/models/form-detail-view-model';
import { CommentAttachment, TRFComment, TRFCommentResponseModel } from 'src/app/travel-requisition/models/travel-requisition-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-conversion',
  templateUrl: './conversion.component.html',
  styleUrls: ['./conversion.component.scss',],
})
export class ConversionComponent implements OnInit {
  @Input() formId:string;
  base_api_url: any = environment.base_api_url;
  commentsResponseModel = new BehaviorSubject<TRFCommentResponseModel>(new TRFCommentResponseModel);
  commentsData: TRFComment[] = [];
  editCommentModel: TRFComment;
  comment_attch = new CommentAttachment()
  attachments :FileList;
  commentAttachments:any;
  fileinput:string;

  totalComments:number;
  masterComment: string = '';
  loggedInUser :any;
  quillConfig={
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        // ['code-block'],           // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],                     // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['clean'],                                         // remove formatting button

        ['link'],
      ],
    },}


  constructor(private vm: FormDetailViewModel,private route: ActivatedRoute,private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.vm.conversionComponent = this;
    this.vm.subscribeComments(this.commentsResponseModel);
    this.vm.getTRFComments(this.formId);
    this.loggedInUser = MapCommon.getloggedInUser(); 
  }


  handleComment(){

    if(this.masterComment.length > 0){
      if(this.editCommentModel){
        this.editCommentModel.comment = this.masterComment;
        this.editCommentModel.modified_by = this.loggedInUser.id;
        this.editCommentModel.attachment = this.attachments;
        this.vm.updateTRFComment(this.editCommentModel);
      } else {
        var newModel = new TRFComment();
        newModel.comment = this.masterComment;
        newModel.trmodel = parseInt(this.formId,10);
        newModel.created_by = this.loggedInUser.id;
        newModel.attachment = this.attachments;
        this.vm.createTRFComment(newModel);
      }
    }
  }
  commentUpdated(){
    this.vm.getTRFComments(this.formId);
    this.editCommentModel = undefined
    this.masterComment = '';
    this.commentAttachments = [];
    this.fileinput = '';
    this.attachments = new FileList();
    
    
  }
  commentsReceived(){
    this.commentsData = this.commentsResponseModel.value.results;
    console.log(this.commentsData)
    this.totalComments = this.commentsData.length;
    
  }

  editComment(comment: TRFComment){
    this.editCommentModel = comment;
    this.masterComment = comment.comment;
    this.commentAttachments = comment.attachment_detail
  }
  
  deleteComment(commentId){
    this.vm.deleteComment(commentId,this.formId);
  }
  onFileChange(event) {
    this.attachments = event.target.files;
  }


  createFileList(files: File[]): FileList {
    const fileList = new ClipboardEvent("").clipboardData || new DataTransfer();
    files.forEach((file) => {
      fileList.items.add(file);
    });
    return fileList.files;
  }

  removeFile(fileToRemove:any){
    if (!this.attachments) {
      return;
    }
    const attachmentsArray = Array.from(this.attachments);
    const filteredArray = attachmentsArray.filter((file) => file !== fileToRemove);
    this.attachments = this.createFileList(filteredArray);
  }

  deleteAttachment(id,comment: TRFComment,edit:boolean=false){
   
    this.vm.deleteCommentAttachment(id,comment,edit)
    
  }

  extractFileName(url: string) {
    const match = url.match(/\/([^\/]+)$/);

    if (match && match[1]) {
      return match[1];
    } else {
      return '';
    }
  }
}
