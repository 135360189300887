<!-- <div class="row mb-2 gutters-sm">
    <div class="col-md-4 mb-3">
        <div class="card shadow">
            <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle"
                        width="150">
                    <div class="mt-3">
                        <h4>{{parentTrfData.first_name}} {{parentTrfData.last_name}}</h4>
                        <p class="text-secondary mb-1">{{parentTrfData.designation}}</p>
                        <p class="text-muted font-size-sm">{{parentTrfData.department}}</p>
                        <a class="btn-sm btn-outline-primary me-1"
                            href="mailto:{{currentUser.email}}">{{parentTrfData.traveller_email}}</a>
                        <a href="{{base_app_url}}/dashboard/submission/edit/{{parentTrfData.id}}" *ngIf="parentTrfData.is_approved==false && parentTrfData.is_disapproved==false && !currentUser.is_superuser" class="btn-sm btn-outline-primary" > Edit</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="card shadow">
            <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Full Name</h6>
                    <span class="text-secondary">{{parentTrfData.first_name}} {{parentTrfData.middle_name}} {{parentTrfData.last_name}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Designation</h6>
                    <span class="text-secondary">{{parentTrfData.designation}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Department</h6>
                    <span class="text-secondary">{{parentTrfData.department}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Employee ID</h6>
                    <span class="text-secondary">{{parentTrfData.employeeid}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Email</h6>
                    <span class="text-secondary">{{parentTrfData.traveller_email}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Birthday</h6>
                    <span class="text-secondary">{{parentTrfData.dob | date : "MMMM d, yyyy"}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Gender</h6>
                    <span class="text-secondary">{{parentTrfData.gender == 'M' ? 'Male' :'Female'}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Phone No.</h6>
                    <span class="text-secondary">{{parentTrfData.mobile}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Passport No.</h6>
                    <span class="text-secondary">{{parentTrfData.passport_number}}</span>
                </li>
            </ul>
        </div>
    </div>
</div> -->

<div class="card shadow-sm user-profile">
    <div class="card-body">
        <div class="row pb-2">
            <div class="col-6">
                <div class="row">
                    <div class="col-3">
                        <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle"
                            width="56">
                    </div>
                    <div class="col-8">
                        <h5 class=" m-0 fw-bold">{{parentTrfData.first_name}} {{parentTrfData.last_name}}</h5>
                        <p>{{parentTrfData.designation}}</p>
                    </div>
                    <div class="col-1 mt-1">
                        <div class="d-flex justify-content-end"> 
                            <span class="badge bg-success" *ngIf="parentTrfData.is_fullfilled">
                                <i class="fas fa-check me-1"></i>
                                Fulfilled
                            </span>
        
                            <span class="badge bg-danger" *ngIf="!parentTrfData.is_fullfilled">
                                <i class="fas fa-times me-1"></i>
                                Not Fulfilled
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="col-6 d-flex justify-content-end">
               <div>
                <a class="btn btn-sm collapse-btn shadow-none" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseUserDetail" aria-expanded="false" aria-controls="collapseUserDetail">
                    <i class="fas fa-angle-down"> </i> 
                </a>
               </div>
            </div>
        </div>
        <div class="row mt-3 collapse" id="collapseUserDetail">
           
            <div class="col-12">
                <hr class="mt-0">
                <!-- <p><strong>Employee ID: </strong>BFIN0136</p>
                <p><strong>Email: </strong>sumit.kumar@buyerforesight.com</p> -->
                <ul class="p-0 m-0">
                    <li class="d-flex justify-content-between w-100">
                        <div class="w-40 fw-bold text-info">
                            Employee ID
                        </div>
                        <div class="w-60">
                            <span class="me-2">:</span> {{parentTrfData.employeeid}}
                        </div>
                    </li>
                    <li class="d-flex justify-content-between w-100">
                        <div class="w-40 fw-bold text-info">
                            Email
                        </div>
                        <div class="w-60">
                            <span class="me-2">:</span> {{parentTrfData.traveller_email}}
                        </div>
                    </li>
                    <li class="d-flex justify-content-between w-100">
                        <div class="w-40 fw-bold text-info">
                            Birthday
                        </div>
                        <div class="w-60">
                            <span class="me-2">:</span> {{parentTrfData.dob | date : "MMMM d, yyyy"}}
                        </div>
                    </li>
                    <li class="d-flex justify-content-between w-100">
                        <div class="w-40 fw-bold text-info">
                            Gender
                        </div>
                        <div class="w-60">
                            <span class="me-2">:</span> {{parentTrfData.gender == 'M' ? 'Male' :'Female'}}
                        </div>
                    </li>
                    <li class="d-flex justify-content-between w-100">
                        <div class="w-40 fw-bold text-info">
                            Phone No.
                        </div>
                        <div class="w-60">
                            <span class="me-2">:</span> {{parentTrfData.mobile}}
                        </div>
                    </li>
                    <li class="d-flex justify-content-between w-100">
                        <div class="w-40 fw-bold text-info">
                            Passport No.
                        </div>
                        <div class="w-60">
                            <span class="me-2">:</span> {{parentTrfData.passport_number}}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div class="user-profile__ribbon-inner">
        <div class="user-profile__ribbon-ribbon">
            <p><strong>Fulfill</strong></p>
        </div>
    </div> -->
</div>