import { Component, OnInit, ViewChild } from '@angular/core';
import { TRFModel,FlightTicketModel,TrainTicketModel,AccomodationModel} from 'src/app/travel-requisition/models/travel-requisition-model';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { Router } from '@angular/router';
import { FormEditViewModel } from './models/form-detail-view-model';
import { AlertNotificationsComponent } from '../Alerts/alert-notifications/alert-notifications.component';


@Component({
  selector: 'app-trf-edit',
  templateUrl: './trf-edit.component.html',
  styleUrls: ['./trf-edit.component.scss']
})
export class TrfEditComponent implements OnInit {
  formId : string;
  base_api_url:any = environment.base_api_url;
  @ViewChild('travellForm') travellForm:any
  headline = 'Edit Travel Requisition'
  inlineRadioOptions:any
  modeOfTravel:any
  trfmodel: any = {};
  flightmodel: any = {};
  trainmodel: any = {};
  accomodation: any = {};
  travelType:string = 'oneWay'
  isLoading:boolean = false
  genderList = [{ id:'M' , name:'Male'},{ id:'F' , name:'Female'}]
  
  trfModel = new BehaviorSubject<TRFModel>(
    new TRFModel()
  );
  flightModel = new BehaviorSubject<FlightTicketModel>(
    new FlightTicketModel()
  );

  trainModel = new BehaviorSubject<TrainTicketModel>(
    new TrainTicketModel()
  );

  accomodationModel = new BehaviorSubject<AccomodationModel>(
    new AccomodationModel()
  );
  

  constructor( private vm: FormEditViewModel,private router: Router) {
    var url = window.location.href;
    this.formId = url.substring(url.lastIndexOf('/') + 1);
    const localStorage_user = MapCommon.getloggedInUser();
    
    this.vm.getFormDetail(this.formId);
    this.isLoading = true
   }
  
  

  detailRecive(){
    this.trfmodel = this.trfModel.value;
    this.flightmodel = this.flightModel.value;
    this.trainmodel = this.trainModel.value;
    this.accomodation = this.accomodationModel.value;
    this.isLoading = false
    this.flightmodel.returndate && (this.travelType = 'roundTrip')
    // console.log(this.trfmodel, this.flightmodel , this.trainmodel , this.accomodation)
  }

  ngOnInit(): void {
    this.vm.parentFormDetail = this
    this.vm.subscribeDetailVariables(this.trfModel,this.flightModel,this.trainModel,this.accomodationModel)

  }

  returnDestinationChange(e){
    if(e){
      this.accomodation.location = this.flightmodel.return_destination
    }
  }

  travelSideChange(e){
    if(e){
      this.flightmodel.returndate = null
    }
  }


  onTravelPurposeChange(e) {
    if( this.trfmodel.travel_purpose == 'sales' || this.trfmodel.travel_purpose == 'internal'){
      this.trfmodel.event_name = undefined
      this.trfmodel.event_location = undefined
      this.trfmodel.event_date = undefined
    }
  }

  update(){
    if (this.travellForm.form.valid ){
      this.isLoading = true
      this.trfmodel.userid = MapCommon.getloggedInUser().id;
      this.vm.updatedata(this.formId,this.trfmodel, this.flightmodel, this.trainmodel, this.accomodation);
    }else{
      document.getElementById('collapseTwo')?.classList.add('show')
      document.getElementById('collapseOne')?.classList.add('show')
      document.getElementById('collapseFour')?.classList.add('show')
      AlertNotificationsComponent.alertCall.showAlert('All fields are required', 'danger');
    }
    this.isLoading = true
    
  }

  updated(){
    this.isLoading = false
    this.router.navigate(['/dashboard']);
  }
}
