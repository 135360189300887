import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
// MDBBootstrap
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbFormControlComponent } from 'mdb-angular-ui-kit/forms';

import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { CommonModule } from '@angular/common';
import { MapAPIService } from './app-providers/services/map-api-service';
import { APIServiceInterceptor } from './app-providers/interceptors/APIService.interceptor';

import { LoginComponent } from './login/login.component';
import { ApproveFormComponent } from './approve-form/approve-form.component';
import { FormListingComponent } from './form-listing/form-listing.component';
import { FormDetailComponent } from './form-listing/form-detail/form-detail.component';
import { AlertNotificationsComponent } from './Alerts/alert-notifications/alert-notifications.component';
import { TravelRequisitionComponent } from './travel-requisition/travel-requisition.component';
import { MainContentComponent } from './form-listing/main-content/main-content.component';
import { TopbarComponent } from './form-listing/main-content/topbar/topbar.component';
import { SidebarComponent } from './form-listing/sidebar/sidebar.component';
import { TrfListComponent } from './trf-list/trf-list.component';
import { TrfEditComponent } from './trf-edit/trf-edit.component';
import { ContentComponent } from './form-listing/main-content/content/content.component';
import { AllSubmissionsComponent } from './form-listing/main-content/all-submissions/all-submissions.component';
import { UserSubmissionsComponent } from './form-listing/main-content/user-submissions/user-submissions.component';
import { SubmissionComponent } from './form-listing/main-content/submission/submission.component';
import { ProfileComponent } from './form-listing/main-content/profile/profile.component';
import { ProfilePictureComponent } from './form-listing/main-content/profile/profile-picture/profile-picture.component';
import { ProfileDetailsComponent } from './form-listing/main-content/profile/profile-details/profile-details.component';
import { CostDetailsComponent } from './form-listing/main-content/submission/cost-details/cost-details.component';
import { DocumentsDetailsComponent } from './form-listing/main-content/submission/documents-details/documents-details.component';
import { EventDetailsComponent } from './form-listing/main-content/submission/event-details/event-details.component';
import { AccommodationDetailsComponent } from './form-listing/main-content/submission/accommodation-details/accommodation-details.component';
import { FlightDetailsComponent } from './form-listing/main-content/submission/flight-details/flight-details.component';
import { UserDetailsComponent } from './form-listing/main-content/submission/user-details/user-details.component';
import { TrainDetailsComponent } from './form-listing/main-content/submission/train-details/train-details.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConversionComponent } from './form-listing/main-content/conversion/conversion.component';
import {QuillModule} from 'ngx-quill';
import { NoSanitizeHtmlPipe } from './app-providers/services/nosanitizerpipe';
import { NavbarComponent } from './form-listing/navbar/navbar.component';
import { UIChangerService } from './app-providers/services/ui-changer.service';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ApproveFormComponent,
    TravelRequisitionComponent,
    FormListingComponent,
    FormDetailComponent,
    AlertNotificationsComponent,
    SidebarComponent,
    MainContentComponent,
    TopbarComponent,
    TrfListComponent,
    TrfEditComponent,
    ContentComponent,
    AllSubmissionsComponent,
    UserSubmissionsComponent,
    SubmissionComponent,
    ProfileComponent,
    ProfilePictureComponent,
    ProfileDetailsComponent,
    CostDetailsComponent,
    DocumentsDetailsComponent,
    EventDetailsComponent,
    AccommodationDetailsComponent,
    FlightDetailsComponent,
    UserDetailsComponent,
    TrainDetailsComponent,
    SpinnerComponent,
    ConversionComponent,
    NoSanitizeHtmlPipe,
    NavbarComponent

    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    // MDBBootstrap
    //MdbModule,
    MdbModalModule,
    MdbFormsModule,

    HttpClientModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    NoopAnimationsModule,
    NgSelectModule,
    QuillModule.forRoot(),


  ],
  providers: [
    MapAPIService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIServiceInterceptor,
      multi: true,
    },
    UIChangerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
