import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { TRFResponseModel } from 'src/app/travel-requisition/models/travel-requisition-model';
import { FormsViewModel } from '../../models/form-view-model';

@Component({
  selector: 'app-all-submissions',
  templateUrl: './all-submissions.component.html',
  styleUrls: ['./all-submissions.component.scss'],
})
export class AllSubmissionsComponent implements OnInit {
  approved: string = 'all';
  all_forms :any= [];
  data: any = {};
  isLoading:boolean = false
  headline='All Submissions'
  tagline='All Submissions Short Description'
  trfResponseModel = new BehaviorSubject<TRFResponseModel>(
    new TRFResponseModel()
  );

  constructor(private vm: FormsViewModel, private router: Router) {
    
  }
  ngOnInit(): void {
    this.vm.allSubmissionsComponent = this;
    this.vm.subscribeAllSubDetail(this.trfResponseModel);
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user.is_superuser == true) {
      this.vm.getforms(this.approved, 'allSubmission');
      this.isLoading = true
    }
  }

  getData(event) {
    const localStorage_user = MapCommon.getloggedInUser();
    // this.isLoading= true
    if (localStorage_user.is_superuser == true) {
      this.vm.getforms(event.target.value , 'allSubmission');
    }else{
      this.vm.getforms(event.target.value, 'allSubmission','true');

    }
  }


 

  detailRecived() {
    this.isLoading= false
    this.all_forms = this.trfResponseModel.value.results;
  }
}
