import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UIChangerService {

  isSubmissionPage: EventEmitter<boolean> = new EventEmitter(false);

  constructor() { }

  updateSubmissionPage(show:boolean): void {
    this.isSubmissionPage.emit(show);
  }

}
