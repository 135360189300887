import { Injectable } from "@angular/core";
import { MapCommon } from "src/app/app-providers/trf-base/MapCommon";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { ApproveFormComponent } from "../approve-form.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { BehaviorSubject,Observer } from 'rxjs';
import { TRFModel,FlightTicketModel,TrainTicketModel,AccomodationModel } from "src/app/travel-requisition/models/travel-requisition-model";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";


@Injectable({
    providedIn: "root",
})

export class ApproveFormViewModel { 
    
    parentApproveForm: ApproveFormComponent;
    parentAppcomponent: AppComponent;

    trfModel = new BehaviorSubject<TRFModel>(
        new TRFModel()
      );
    
    flightModel = new BehaviorSubject<FlightTicketModel>(
    new FlightTicketModel()
    );

    trainModel = new BehaviorSubject<TrainTicketModel>(
        new TrainTicketModel()
        );

    accomodationModel = new BehaviorSubject<AccomodationModel>(
        new AccomodationModel()
        );
    
    subscribeDetailVariables(observerTRFModel: Observer<TRFModel>, observerFlightModel: Observer<FlightTicketModel>, observerTrainModel: Observer<TrainTicketModel>, observerAccomodationModel: Observer<AccomodationModel>  ) {
    this.trfModel.subscribe(observerTRFModel);
    this.flightModel.subscribe(observerFlightModel);
    this.trainModel.subscribe(observerTrainModel);
    this.accomodationModel.subscribe(observerAccomodationModel);
}

    constructor(private apiService: MapAPIService) { } 

    performAction(action: boolean,id:string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.ACTION + '?approved=' + action+"&id="+id,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert(action == true ? 'Request Approved' : 'Request Dispproved', 'primary');
                this.parentApproveForm.requestProcesed(e)
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getTRFdetail(id: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.LEAD + '/' + id,
            Success: (e: any) => {
                
                this.trfModel.next(e);               
                this.flightModel.next(e.flightdetail);
                this.trainModel.next(e.traindetail);
                this.accomodationModel.next(e.accomodationdetail);
                this.parentApproveForm.detailRecive();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error'+ e, 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    

  

   
}