import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginUserModel } from 'src/app/login/models/login-model';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  currentPath: string
  show_title = false;
  class_name: string = "";
  currentUser: LoginUserModel = null;
  @Input('headline') headline :any 
  @Input('tagline') tagline:any
  constructor(private router: Router) {
    const localStorage_user = MapCommon.getloggedInUser();
    this.currentUser = localStorage_user
    // this.currentPath = this.router.url
    this.currentPath = this.router.url.split('?')[0];
    if (this.currentPath == '/forms') {
      this.show_title = true;
      this.class_name = "justify-content-between";
    } else {
      this.show_title = false;
      this.class_name = "justify-content-end";
    }

    // console.log(this.router.url)
  }


  ngOnInit(): void {
  }

  logout(){
    MapCommon.removeItemsOnLogOut();
    this.router.navigate(['/login'])
  }

}
