import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertNotificationsComponent } from 'src/app/Alerts/alert-notifications/alert-notifications.component';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { FormsViewModel } from 'src/app/form-listing/models/form-view-model';
import { LoginUserModel } from 'src/app/login/models/login-model';
import { Profile, ProfileResponseModel } from 'src/app/travel-requisition/models/travel-requisition-model';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnInit {
  // currentUser: LoginUserModel = null;
  currentUser: any = {};
  profileResponseModel = new BehaviorSubject<ProfileResponseModel>(
    new ProfileResponseModel()
    );
    Profile :Profile = new Profile()
    isLoading:boolean = false
    genderList = [{ id:'M' , name:'Male'},{ id:'F' , name:'Female'}]

  constructor(private vm: FormsViewModel) {
    this.vm.profileDetailsComponent = this
    const localStorage_user = MapCommon.getloggedInUser();
    this.currentUser = localStorage_user;
    // console.log('currentUser', this.currentUser);
  }

  ngOnInit(): void {
    this.vm.subscribeProfileDetail(this.profileResponseModel)
    this.getProfile()
    
  }

  getProfile(){
    this.vm.getProfileDetails() 
    this.isLoading= true
  }

  setProfileData(){
    // console.log( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.Profile.manager.trim()))
    if (this.Profile.dob==""){
      this.Profile.dob = null;
    }
    if(!this.Profile.manager){
      this.Profile.user = this.currentUser.id
      this.vm.setProfileDetails(this.Profile)
      this.isLoading= true
    } else if(this.Profile.manager && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.Profile.manager.trim())){
      this.Profile.user = this.currentUser.id
      this.vm.setProfileDetails(this.Profile)
      this.isLoading= true
    } else{
      this.isLoading= false
      AlertNotificationsComponent.alertCall.showAlert('Manager Email is not valid', 'danger');
    }
    
  }

  profileUpdated(){
    this.isLoading= false
  }

  DataRecieved(){
    this.isLoading= false
    this.profileResponseModel.value.results.length > 0 &&( this.Profile = this.profileResponseModel.value.results[0])
    if(this.Profile.gender == null){
      this.Profile.gender = undefined
    }
  }
}
