<div class="position-sticky ">
<!-- <div class="position-fixed"> -->
    <ul class="nav flex-column">
        <a class="sidebar-brand d-flex align-items-center justify-content-center my-3" routerLink=" ">
            <div class="sidebar-brand-icon rotate-n-15">
               <img src="../../assets/images/bfs-logo.png" alt="">
            </div>
        </a>
        <hr class="sidebar-divider my-0">
        <li class="nav-item mt-2" [ngClass]="isActive('dashboard') ? 'active' : 'inactive'">
            <a class="nav-link" routerLink="">
                <i class="fas fa-tachometer-alt me-2"></i> Dashboard
            </a>
        </li>
        <li class="nav-item" *ngIf="show_only_for_admin()" [ngClass]="isActive('dashboard/all-submissions') ? 'active' : 'inactive'">
            <a class="nav-link" routerLink="all-submissions">
                <i class="fas fa-user me-2"></i> Submissions
            </a>
        </li>
        <li class="nav-item" *ngIf="show_only_for_user()" [ngClass]="isActive('user-submissions') ? 'active' : 'inactive'">
            <a class="nav-link" routerLink="user-submissions">
                <i class="fas fa-user me-2"></i> Submissions
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="profile" [ngClass]="isActive('dashboard/profile') ? 'active' : 'inactive'">
                <i class="fas fa-id-card me-2"></i> Profile
            </a>
        </li>
        <!-- Add more menu items as needed -->
    </ul>
</div>