import { Injectable } from "@angular/core";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapCommon } from "src/app/app-providers/trf-base/MapCommon";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { BehaviorSubject ,Observer } from 'rxjs';
import { TRFResponseModel,CountModel } from "src/app/travel-requisition/models/travel-requisition-model";
import { TrfListComponent } from "../trf-list.component";

@Injectable({
    providedIn: "root",
})

export class TRFViewModel { 
    

    parentForms: TrfListComponent;
    parentAppcomponent: AppComponent;
    nodataFound:boolean=false;

    trfResponseModel = new BehaviorSubject<TRFResponseModel>(
        new TRFResponseModel()
        );
    
    countModel = new BehaviorSubject<CountModel>(
        new CountModel()
        );
        
    
    subscribeDetail(observerTRFResponseModel: Observer<TRFResponseModel>) {

        this.trfResponseModel.subscribe(observerTRFResponseModel);

}

    constructor(private apiService: MapAPIService) { } 


    getforms() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.LEAD +"?get_user_list="+true ,
            Success: (e: any) => {
                if (e.count==0){
                    this.nodataFound=true;
                }
                this.trfResponseModel.next(e);
                
                    this.parentForms.detailRecived();
                 
                
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error While getting forms data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

  
}