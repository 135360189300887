<div class="d-flex align-items-center justify-content-between pb-2">
    <h6>

        <i class="fas fa-plane me-2 section-icon"></i> <span class="section-heading  fw-bold">Flight Details</span>
    </h6>
    

    <div class="col-6 d-flex justify-content-end">
        <div>
            <a class="btn btn-sm collapse-btn shadow-none"  type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFlight" aria-expanded="false" aria-controls="collapseFlight">
                <i class="fas fa-angle-down me-1"> </i> 
            </a>
        </div>
     </div>


</div>
<div class="collapse mt-2" id="collapseFlight">
    
    <div class="col-12">
        <hr class="mt-0">
    </div>

    <ul class="p-0">
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                From
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentFlight.destination ? parentFlight.destination : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                To
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentFlight.return_destination ? parentFlight.return_destination : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Departure
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentFlight.traveldate ? (parentFlight.traveldate | date : "MMMM d, yyyy") : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Return
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentFlight.returndate ?( parentFlight.returndate | date : "MMMM d, yyyy") : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Travel Class
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentFlight.travelclass ? parentFlight.travelclass : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Seat Preference
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentFlight.seat_preference_display ? parentFlight.seat_preference_display : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Prefered Time
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentFlight.preferedtime ? parentFlight.preferedtime : 'NA'}}
            </div>
        </li>
    </ul>
</div>