import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { LoginUserModel } from 'src/app/login/models/login-model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  currentUser_is_superuser: boolean = false;
  currentRoute: string = 'dashboard';

  currentUser: LoginUserModel = null;
  
  constructor(private router: Router) {
    const localStorage_user = MapCommon.getloggedInUser();
    this.currentUser_is_superuser = localStorage_user.is_superuser;
    this.currentUser = localStorage_user
    // console.log(this.currentUser_is_superuser);
  }

  ngOnInit(): void {}

  // show_only_for_user() {
  //   if (this.currentUser_is_superuser) {
  //     return false;
  //   }
  //   return true;
  // }
  // show_only_for_admin() {
  //   if (this.currentUser_is_superuser) {
  //     return true;
  //   }
  //   return false;
  // }

  // isActive(route: string): boolean {
  //   console.log('route', route);
   
  //   return this.currentRoute === route;
  // }

  navigateTo(route: string): void {
     console.log('currentRoute', this.currentRoute);
    this.currentRoute = route;
  }

  logout(){
    MapCommon.removeItemsOnLogOut();
    this.router.navigate(['/login'])
  }

}
