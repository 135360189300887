<!-- Top bar -->
<!-- <app-topbar [headline]="headline"></app-topbar> -->
<!-- <app-user-details [parentTrfData]="trfmodel"></app-user-details> -->





<div class="row">
    <div class="col-8 p-0">
        <div class="container submission-wrapper">
            <div class="row pt-3 pb-5">
                <div class="col-12">
                    <app-user-details [parentTrfData]="trfmodel"></app-user-details>
                </div>
            
                <div class="col-12 ">
                    <div class="card shadow-sm mt-3">
                        <div class="card-body">
                            <app-event-details [parentEventData]="trfmodel"></app-event-details>
                        </div>
                    </div>
                </div>
            
                <div class="col-12" *ngIf="mode_of_travel_flight" >
                    <div class="card mt-3 shadow-sm">
                        <div class="card-body">
                            <app-flight-details [parentFlight]="flightmodel"></app-flight-details>
                        </div>
                    </div>
                </div>
            
                <div class="col-12"  *ngIf="accommodation">
                    <div class="card mt-3 shadow-sm">
                        <div class="card-body">
                            <app-accommodation-details  [parentAccDetail]="accomodation"></app-accommodation-details>
                        </div>
                    </div>
                </div>
            
                <div class="col-12" *ngIf="mode_of_travel_train" >
                    <div class="card mt-3 shadow-sm" [ngStyle]="{'display':mode_of_travel_train?'block':'none'}">
                        <div class="card-body">
                            <app-train-details  [parentTrain]="trainmodel"></app-train-details>
                        </div>
                    </div>
                </div>
                <div class="col-12"  *ngIf="show_only_for_admin()">
                    <div class="card mt-3 shadow-sm">
                        
                        <app-cost-details [parentCostData]="travelcost"  [is_approved]="trfmodel.is_approved"></app-cost-details>
                        <div *ngIf="show_only_for_admin()" class="d-flex justify-content-center my-3">
                            <button class="btn btn-outline-primary" [disabled]="!trfmodel.is_approved" (click)="addCost()">Add Cost</button>
                        </div>
                      
                    </div>
                </div>
                
                <div class="col-12">
                       
                    <div class="card mt-3 shadow-sm">
                            <app-documents-details [parentDocument]="remarks" [is_approved]="trfmodel.is_approved"
                            (fileEvent)="onFileChange($event)"></app-documents-details>
                            <div *ngIf="show_only_for_admin()" class="d-flex justify-content-center my-3">
                                <button class="btn btn-outline-primary" [disabled]="!trfmodel.is_approved" (click)="markdone()">Mark FulFilled</button>
                            </div>
                       
                    </div>
                    
                </div>
            </div> 
        </div>

    </div>
    <app-conversion [formId]="formId" class="col-4 p-0 bg-white conversion-block"></app-conversion> 
</div>





<app-spinner *ngIf="isLoading"></app-spinner>