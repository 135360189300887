import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginUserModel } from './models/login-model';
import { MapCommon } from '../app-providers/trf-base/MapCommon';
import { LoginViewModel } from './models/login-view-model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  usernameModel: string = '';
  passwordModel: string = '';
  loginError: string = '';
  currentUser: LoginUserModel = null;
  isLoggedIn: boolean = false;



  constructor(private vm: LoginViewModel, private router: Router) {
  const localStorage_user = MapCommon.getloggedInUser();
  if (localStorage_user){
    this.currentUser = localStorage_user
    this.isLoggedIn = true;
    this.router.navigate(['/dashboard']);
  }
  const currentURL = window.location.href;

  if (currentURL.includes('login?code=')) {
    const startIX = currentURL.indexOf('code') + 5;
    const endIX = currentURL.indexOf('&');
    const authCode = currentURL.substring(startIX, endIX);
    const requestObj = {
      auth_code: decodeURIComponent(authCode),
      provider: 'Google',
      redirect_uri:environment.base_app_url+'/login'

    }
    this.vm.logMeIn(requestObj);
  }
   this.vm.parentLogin = this;
  }



  ngOnInit(): void {
    // document.body.style.backgroundColor = "#020202";
  }

  userDetailReceived(){
    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user){
      this.currentUser = localStorage_user;
      this.isLoggedIn = true;
      this.router.navigate(['/dashboard']);
    } else {
      this.loginError = "Unable to login, please check with admin."
    }

  }

  // Login(){
  //   if (this.usernameModel.length === 0 || this.passwordModel.length === 0){
  //     this.loginError = "Username or password can't be empty"
  //   } else {
  //     const encodedCred = btoa(this.usernameModel + '||' + this.passwordModel)
  //     this.vm.logMeIn(encodedCred);
  //   }
  // }

  connectWithGoogle(): void {
    window.open('https://accounts.google.com/o/oauth2/auth/oauthchooseaccount'
    + '?redirect_uri=' + environment.base_app_url+'/login' 
    + '&client_id=254591168563-ftt13ic111oeonll7jp4pcmq218lt7hf.apps.googleusercontent.com' 
    + '&response_type=code'
    + '&scope=email profile'
    + '&access_type=online', '_self');
  }

}