import { Component, OnInit } from '@angular/core';
import { MapCommon } from '../app-providers/trf-base/MapCommon';
import { Router } from '@angular/router';
import { ApproveFormViewModel } from './models/approve-form-view-model';
import {
  TRFModel,
  FlightTicketModel,
  TrainTicketModel,
  AccomodationModel,
} from '../travel-requisition/models/travel-requisition-model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-approve-form',
  templateUrl: './approve-form.component.html',
  styleUrls: ['./approve-form.component.scss'],
})
export class ApproveFormComponent implements OnInit {
  currentUser: any = {};
  ticketId :string;
  mode_of_travel_flight:boolean = false;
  mode_of_travel_train:boolean = true;
  accommodation: boolean = true;
  test:string = '';
  isLoading:boolean =false
  // trfmodel: TRFModel = null;
  loading: boolean = true;
  hideclass: string = 'hidden';
  result: any = {};
  trfmodel: any = {};
  flightmodel: any = {};
  trainmodel: any = {};
  accomodation: any = {};
  trfModel = new BehaviorSubject<TRFModel>(new TRFModel());
  flightModel = new BehaviorSubject<FlightTicketModel>(new FlightTicketModel());
  approveS:boolean
  disapprove:boolean
  trainModel = new BehaviorSubject<TrainTicketModel>(new TrainTicketModel());

  accomodationModel = new BehaviorSubject<AccomodationModel>(
    new AccomodationModel()
  );

  constructor(private vm: ApproveFormViewModel, private router: Router) {
    const currentURL = window.location.href;
    const startIX = currentURL.indexOf('id') + 3;
    const str_id = currentURL.substring(startIX);
    this.ticketId = str_id
    this.result = this.vm.getTRFdetail(str_id);
    setTimeout(() => {
      const localStorage_user = MapCommon.getloggedInUser();
      // Hide the loading indicator when the work is done
      this.currentUser = localStorage_user
      if (localStorage_user.email == this.trfmodel.approver || localStorage_user.is_superuser) {
        this.loading = false;
        this.hideclass = '';
      } else {
        this.router.navigate(['/travelform']);
      }
    }, 2000);

    // 
    
  }

  detailRecive() {
    this.trfmodel = this.trfModel.value;
    this.flightmodel = this.flightModel.value;
    this.trainmodel = this.trainModel.value;
    this.accomodation = this.accomodationModel.value;
    this.approveS = this.trfmodel.is_approved;
    this.disapprove = this.trfmodel.is_disapproved;
    // flight details hide/show
    if (this.flightModel.value.destination && this.flightModel.value.travelclass) {
      this.mode_of_travel_flight = true;
    } else {
      this.mode_of_travel_flight = false;
    }

    // train details hide/show
    if (this.trainModel.value.fromstation && this.trainModel.value.tostation) {
      this.mode_of_travel_train = true;
    } else {
      this.mode_of_travel_train = false;
    }

    // accommodation details hide/show
    if (this.accomodationModel.value.checkin && this.accomodationModel.value.checkout) {
      this.accommodation = true;
    } else {
      this.accommodation = false;
    }
  }

  ngOnInit(): void {
    this.vm.parentApproveForm = this;
    this.vm.subscribeDetailVariables(
      this.trfModel,
      this.flightModel,
      this.trainModel,
      this.accomodationModel
    );
  }

  approve(is_approve: boolean) {
    this.isLoading = true
    this.approveS = is_approve;
    this.vm.performAction(this.approveS,this.ticketId);
  }

  requestProcesed(e){
    this.isLoading = false
    this.approveS = e.is_approved;
    this.disapprove = e.is_disapproved;
  }
}
 