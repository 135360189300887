import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { MapCommon } from "../trf-base/MapCommon";

@Injectable()
export class APIServiceInterceptor implements HttpInterceptor {

    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => this.handleError(error, req, next))
        );
    }
    private handleError(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {

        // Unauthorized
        if (err.status == 401) {
            MapCommon.removeItemsOnLogOut();
            this.router.navigateByUrl('/unauthorized');
            // window.location.reload(); 
        }
        // service error
        if (err.status == 503) {
            this.router.navigateByUrl('/maintenance-mode');
        }


        // rethrow Error
        return throwError(err);
    }
}
