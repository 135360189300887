<app-topbar></app-topbar>
<!-- <div *ngIf="loading">Loading.....</div> -->
<!-- Loading Spinner -->
<div class="container" *ngIf="loading">
  <div class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="spinner-grow" style="width: 3rem; height: 3rem;color: #c1011a;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    
  </div>
</div>
<section class="{{ hideclass }} checkout standard-text mt-4 pv-100 w3_bg">
  <div class="container w3_bg">
    <div class="row justify-content-center checkout__forms mt-3 r-gap-40 w3_bg">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary ">
            <i class="fas fa-address-card">
             </i>  Mandatory Information of The Traveler
          </h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h6 class="d-flex align-items-center mb-3 fw-bold border-bottom pb-2">
                <i class="material-icons text-info mr-2"></i> Personal Information
              </h6>
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Full Name</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.first_name }} {{ trfmodel.middle_name }}
                  {{ trfmodel.last_name }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Employee ID</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.employeeid }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Department</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.department }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Email</h6>
                </div>
                <div class="col-sm-8 text-secondary  ">
                  {{ trfmodel.traveller_email }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Designation</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.designation }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Birthday</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.dob | date : "MMMM d, yyyy" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Gender</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.gender == 'M' ? 'Male' :'Female' }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Mobile</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.mobile }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Passport Number</h6>
                </div>
                <div class="col-sm-8 text-secondary text-capitalize">
                  {{ trfmodel.passport_number }}
                </div>
              </div>
              <hr />
            </div>
            <div class="col-md-6">
              <div class="col-md-12">
                <h6 class="d-flex align-items-center mb-3 fw-bold border-bottom pb-2">
                  <i class="material-icons text-info mr-2"></i> Event Details
                </h6>
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="mb-0">Event Location</h6>
                  </div>
                  <div class="col-sm-8 text-secondary text-capitalize">
                    {{ trfmodel.event_location ? trfmodel.event_location : 'NA' }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="mb-0">Region</h6>
                  </div>
                  <div class="col-sm-8 text-secondary text-capitalize">{{ trfmodel.region }} </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="mb-0">Purpose of travel</h6>
                  </div>
                  <div class="col-sm-8 text-secondary text-capitalize">  {{ trfmodel.travel_purpose }} </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="mb-0">Customer/Event name</h6>
                  </div>
                  <div class="col-sm-8 text-secondary text-capitalize">
                    {{ trfmodel.event_name ? trfmodel.event_name : 'NA'  }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="mb-0">Approver Email</h6>
                  </div>
                  <div class="col-sm-8 text-secondary  ">
                    {{ trfmodel.approver }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="mb-0">Event Date</h6>
                  </div>
                  <div class="col-sm-8 text-secondary text-capitalize">
                    {{ trfmodel.event_date ? (trfmodel.event_date | date : "MMMM d, yyyy") : 'NA' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-4" *ngIf="mode_of_travel_train">
            <div class="col-md-12">
              <h6 class="d-flex align-items-center mb-3 fw-bold border-bottom pb-2">
                <i class="material-icons text-info mr-2"></i> Train Details
              </h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                   
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">From (Station Name)</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ trainmodel.fromstation ? trainmodel.fromstation : 'NA' }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">To (Station Name)</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ trainmodel.tostation ? trainmodel.tostation : 'NA'  }}
                    </div>
                  </div>
                  <hr />
                    <div class="col-sm-6">
                      <h6 class="mb-0">Date of Journey</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ trainmodel.startdate ? ( trainmodel.startdate | date : "MMMM d, yyyy")  : 'NA'}}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">Train Name / Number</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ trainmodel.train ? trainmodel.train : 'NA' }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">Class</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ trainmodel.ticketclass ? trainmodel.ticketclass : 'NA'  }}
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <div class="row my-4" *ngIf="mode_of_travel_flight">
            <div class="col-md-12">
              <h6 class="d-flex align-items-center mb-3 fw-bold border-bottom pb-2">
                <i class="material-icons text-info mr-2"></i> Flight Details
              </h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">
                        From
                      </h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ flightmodel.destination ?  flightmodel.destination : 'NA' }}
                    </div>
                  </div>
                 
                 
                 
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">
                        To
                      </h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ flightmodel.return_destination ? flightmodel.return_destination : 'NA' }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">Departure</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ flightmodel.traveldate ? ( flightmodel.traveldate | date : "MMMM d, yyyy") : 'NA' }}
                    </div>
                  </div>
                  
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">Return</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ flightmodel.returndate ? (flightmodel.returndate | date : "MMMM d, yyyy") : 'NA' }}
                    </div>
                  </div>
                  
                  
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">Travel Class</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">

                      {{ flightmodel.travelclass ? flightmodel.travelclass : 'NA' }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">Seat Preference</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                     
                      {{flightmodel.seat_preference_display ? flightmodel.seat_preference_display : 'NA'}}
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0">Prefered Time</h6>
                    </div>
                    <div class="col-sm-6 text-secondary text-capitalize">
                      {{ flightmodel.preferedtime ? flightmodel.preferedtime : 'NA' }}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div class="row my-4" *ngIf="accommodation">
            <div class="col-md-12">
              <h6 class="d-flex align-items-center mb-3 fw-bold border-bottom pb-2">
                <i class="material-icons text-info mr-2"></i> Accommodation Details
              </h6>
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0">Location</h6>
                </div>
                <div class="col-sm-6 text-secondary text-capitalize">
                  {{ accomodation.location ? ( accomodation.location | titlecase) : 'NA' }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0">Prefered Location</h6>
                </div>
                <div class="col-sm-6 text-secondary text-capitalize">
                  {{ accomodation.preferedlocation ? accomodation.preferedlocation : 'NA' }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0">Occupancy</h6>
                </div>
                <div class="col-sm-6 text-secondary text-capitalize">
                  {{ accomodation.occupancy ? accomodation.occupancy : 'NA' }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0">Meal Preference</h6>
                </div>
                <div class="col-sm-6 text-secondary text-capitalize">
                  {{ accomodation.meal_preference_display ? accomodation.meal_preference_display : 'NA' }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0">Dietary Restriction</h6>
                </div>
                <div class="col-sm-6 text-secondary text-capitalize">
                  {{ accomodation.dietary_restriction ? accomodation.dietary_restriction : 'NA' }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0">Check In</h6>
                </div>
                <div class="col-sm-6 text-secondary text-capitalize">
                  {{accomodation.checkin ? (accomodation.checkin | date : "MMMM d, yyyy") : 'NA' }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0">Check Out</h6>
                </div>
                <div class="col-sm-6 text-secondary text-capitalize">
                  {{ accomodation.checkout ? (accomodation.checkout | date : "MMMM d, yyyy") : 'NA' }}
                </div>
              </div>
              <hr />
              
            </div>
          </div>
           
          <div class="d-flex justify-content-evenly my-3" *ngIf="!currentUser.is_superuser">
            <button id="form_submit" type="button" class="btn btn-success approve" [disabled]="approveS || disapprove" (click)="approve(true)">
              Approve
            </button>
            <button id="form_submit" type="button" [disabled]="approveS || disapprove" class="btn btn-warning disapprove" (click)="approve(false)">
              DisApprove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-spinner *ngIf="isLoading"></app-spinner> 