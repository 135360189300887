<div class="card mb-4 shadow-sm">
    <div class="card-header text-secondary fw-bold"><i class="fas fa-address-card me-2"></i> Profile Details</div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 mb-2">
                <app-profile-picture></app-profile-picture>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4">
                <mdb-form-control class="mb-2">
                    <input mdbInput type="name" id="first_name" name="first_name" class="form-control" [disabled]="true"
                        [value]="currentUser.first_name" />
                    <label mdbLabel class="form-label" for="first_name">First Name</label>
                </mdb-form-control>
            </div>
            <div class="col-md-4">
                <mdb-form-control class="mb-2">
                    <input mdbInput type="text" id="middle_name" name="middle_name" class="form-control"
                        [disabled]="true" [(ngModel)]="currentUser.middle_name" />
                    <label mdbLabel class="form-label" for="middle_name">Middle Name</label>
                </mdb-form-control>
            </div>
            <div class="col-md-4">
                <mdb-form-control class="mb-2">
                    <input mdbInput type="text" id="last_name" name="last_name" class="form-control" [disabled]="true"
                        [(ngModel)]="currentUser.last_name" />
                    <label mdbLabel class="form-label" for="last_name">Last Name</label>
                </mdb-form-control>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6">
                <mdb-form-control class="mb-2">
                    <input mdbInput type="text" id="designation" name="designation" [(ngModel)]="Profile.designation" class="form-control" />
                    <label mdbLabel class="form-label" for="designation">Designation</label>
                </mdb-form-control>
            </div>
            <div class="col-md-6">
                <mdb-form-control class="mb-2">
                    <input mdbInput type="text" id="department" name="department" [(ngModel)]="Profile.department" class="form-control" />
                    <label mdbLabel class="form-label" for="department">Department
                    </label>
                </mdb-form-control>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-md-12">
                <mdb-form-control class="mb-2">
                    <input mdbInput type="text" id="email" name="email"  class="form-control"
                        [(ngModel)]="currentUser.email" [disabled]="true" />
                    <label mdbLabel class="form-label" for="email">Email</label>
                </mdb-form-control>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-md-4">
                <mdb-form-control class="mb-2">
                    <input mdbInput type="text" id="employee_id" [(ngModel)]="Profile.employeeid" name="employee_id" class="form-control" />
                    <label mdbLabel class="form-label" for="employee_id">Employee ID</label>
                </mdb-form-control>
            </div>
            <div class="col-md-4">
                <div class="mb-2 form-outline " >
                    <input type="date" id="dob" name="dob" [ngClass]="{'active': Profile.dob !== null || Profile.dob !== undefined}"  [(ngModel)]="Profile.dob" class="form-control border border-1  rounded-2" style="border-color:#b7b5b5" />
                    <label mdbLabel class="form-label" for="dob">D.O.B</label>
                </div>
            </div>
            <div class="col-md-4">
                <select class="form-select" aria-label="Default select example" name="gender" [(ngModel)]="Profile.gender">
                    <option selected  value="undefined"> Gender</option>
                    <option *ngFor="let gen of genderList"  value={{gen.id}}> {{gen.name}}</option>
                </select>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-md-4">
                <mdb-form-control class="mb-2">
                    <input mdbInput class="form-control" id="mobile" type="text" name="mobile" [(ngModel)]="Profile.mobile" />
                    <label mdbLabel class="form-label" for="mobile">Mobile</label>
                </mdb-form-control>
            </div>
            <div class="col-md-4">
                <mdb-form-control class="mb-2">
                    <input mdbInput class="form-control" id="passport-number" name="passport-number" [(ngModel)]="Profile.passport_number" />
                    <label mdbLabel class="form-label" for="passport-number">Passport number</label>
                </mdb-form-control>
            </div>
            <div class="col-md-4">
                <mdb-form-control class="mb-2">
                    <input mdbInput class="form-control" id="manager" type="text" name="manager"  [(ngModel)]="Profile.manager" />
                    <label mdbLabel class="form-label" for="manager">Manager Email</label>
                </mdb-form-control>
            </div>
        </div>
        <!-- Save changes button-->
        <button class="btn btn-primary" type="button" (click)="setProfileData()">Save changes</button>
    </div>
</div>
<app-spinner *ngIf="isLoading"></app-spinner> 
 