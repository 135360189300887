import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginUserModel } from './login/models/login-model';
import { Router } from '@angular/router';
import { MapCommon } from './app-providers/trf-base/MapCommon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'bfs_traveller_frontend';
  public href: string = '';

  currentUser: LoginUserModel = null;
  isLoggedIn: boolean = false;

  constructor(private router: Router) {
    const localStorage_user = MapCommon.getloggedInUser();
    const currentURL = window.location.href;
    if (!localStorage_user && !currentURL.includes("/login") ){
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void { }

  isLoginRoute(): boolean {
    return this.router.url.includes("/login");
  }
  isTravelRequisitionRoute(): boolean {
    return this.router.url === '/travelform';
  }

  isDashboardRoute(): boolean {
    return this.router.url === '/dashboard';
  }
}
