import { Injectable } from "@angular/core";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapCommon } from "src/app/app-providers/trf-base/MapCommon";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { FormListingComponent } from "../form-listing.component";
import { BehaviorSubject ,Observer } from 'rxjs';
import { TRFResponseModel,CountModel, ProfileResponseModel, Profile } from "src/app/travel-requisition/models/travel-requisition-model";
import { MainContentComponent } from "../main-content/main-content.component";
import { ContentComponent } from "../main-content/content/content.component";
import { AllSubmissionsComponent } from "../main-content/all-submissions/all-submissions.component";
import { UserSubmissionsComponent } from "../main-content/user-submissions/user-submissions.component";
import { ProfileDetailsComponent } from "../main-content/profile/profile-details/profile-details.component";

@Injectable({
    providedIn: "root",
})

export class FormsViewModel { 
    

    parentForms: ContentComponent;
    mainContentComponent: MainContentComponent;
    allSubmissionsComponent: AllSubmissionsComponent;
    userSubmissionsComponent: UserSubmissionsComponent;
    profileDetailsComponent:ProfileDetailsComponent
    parentAppcomponent: AppComponent;

    trfResponseModel = new BehaviorSubject<TRFResponseModel>( new TRFResponseModel );
    profileResponseModel = new BehaviorSubject<ProfileResponseModel>(
        new ProfileResponseModel()
        );
    
    countModel = new BehaviorSubject<CountModel>(
        new CountModel()
        );
        
    
    subscribeDetail(observerTRFResponseModel: Observer<TRFResponseModel>,observerCount:Observer<CountModel> ) {

        this.trfResponseModel.subscribe(observerTRFResponseModel);
        this.countModel.subscribe(observerCount);}
        
    subscribeProfileDetail(observerProfileesponseModel: Observer<ProfileResponseModel> ) {

        this.profileResponseModel.subscribe(observerProfileesponseModel);
       }

    subscribeAllSubDetail(observerTRFResponseModel: Observer<TRFResponseModel> ) {

        this.trfResponseModel.subscribe(observerTRFResponseModel);
    }

    constructor(private apiService: MapAPIService) { } 


    getforms(approved: string, caller,get_user_list:string='false') {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.LEAD + '?approved=' + approved+'&get_user_list='+get_user_list,
            Success: (e: any) => {
                this.trfResponseModel.next(e);
        
                if( caller == 'content'){
                    this.parentForms.detailRecived()
                }
                if( caller == 'allSubmission'){
                    this.allSubmissionsComponent.detailRecived();
                }
                if( caller == 'userSubmission'){
                    this.userSubmissionsComponent.detailRecived();
                }
                
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error While getting forms data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getProfileDetails() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.PROFILE,
            Success: (e: any) => {
        
                this.profileResponseModel.next(e);
                this.profileDetailsComponent.DataRecieved()
                // console.log(e)
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error While profile data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    setProfileDetails(data) {
        let d: IApiServiceDelegate = {
            Data: data,
            Method: "POST",
            Action: MapAPICollection.PROFILE,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Profile Update Successful', 'primary');
                this.profileDetailsComponent.profileUpdated()
                return e;
            },
            Error: (e: String) => {

                AlertNotificationsComponent.alertCall.showAlert('Error while creating duplicate', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }
 


    getcounts(caller) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.GETCOUNTS ,
            Success: (e: any) => {
                this.countModel.next(e);
               caller == 'main' && this.mainContentComponent.countdetailRecived()
               caller == 'cont' && this.parentForms.countdetailRecived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error While getting forms counts', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    

  

   
}