<div class="main-loader"> 
<div class="spinner-grow spinner-grow-sm text-primary ms-1" role="status">
    <span class="visually-hidden">Loading...</span>
</div>
<div class="spinner-grow spinner-grow-sm text-secondary ms-1" role="status">
    <span class="visually-hidden">Loading...</span>
</div>
<div class="spinner-grow spinner-grow-sm text-primary ms-1" role="status">
    <span class="visually-hidden">Loading...</span>
</div>
<div class="spinner-grow spinner-grow-sm text-secondary ms-1" role="status">
    <span class="visually-hidden">Loading...</span>
</div>
<div class="spinner-grow spinner-grow-sm text-primary ms-1" role="status">
    <span class="visually-hidden">Loading...</span>
</div>
</div>