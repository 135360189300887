import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TRFResponseModel , CountModel} from 'src/app/travel-requisition/models/travel-requisition-model';
import { FormsViewModel } from '../models/form-view-model';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {

  approved:string = 'true';
  all_forms = [];
  data:any={};
  count:any={};

  countModel = new BehaviorSubject<CountModel>(
    new CountModel()
  );

  trfResponseModel = new BehaviorSubject<TRFResponseModel>(
    new TRFResponseModel()
  );

  constructor( private vm: FormsViewModel,private router: Router) {
    this.count = this.vm.getcounts('main');

    const localStorage_user = MapCommon.getloggedInUser();
    if(localStorage_user.is_superuser==true){
      this.vm.getforms(this.approved,'main');

    }

   }

   countdetailRecived(){
    this.data = this.countModel.value
   }
  
  getData(event){
    // console.log(event.target.value,"event.target.value")
    this.vm.getforms(event.target.value,'main');

  }

  detailRecived(){
    this.all_forms = this.trfResponseModel.value.results;
  }
  

  ngOnInit(): void {
    this.vm.mainContentComponent = this
    this.vm.subscribeDetail(this.trfResponseModel,this.countModel);
    
  }
}
