import { Injectable } from "@angular/core";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapCommon } from "src/app/app-providers/trf-base/MapCommon";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { BehaviorSubject,Observer } from 'rxjs';
import { TRFModel,FlightTicketModel,TrainTicketModel,AccomodationModel } from "src/app/travel-requisition/models/travel-requisition-model";
import { TravelCost,RemarksModel } from "src/app/travel-requisition/models/travel-requisition-model";
import { HttpHeaders } from "@angular/common/http";
import { TrfEditComponent } from "../trf-edit.component";



@Injectable({
    providedIn: "root",
})

export class FormEditViewModel { 
    
    parentFormDetail: TrfEditComponent;
    parentAppcomponent: AppComponent;

    trfModel = new BehaviorSubject<TRFModel>(
        new TRFModel()
      );
    
    flightModel = new BehaviorSubject<FlightTicketModel>(
    new FlightTicketModel()
    );

    trainModel = new BehaviorSubject<TrainTicketModel>(
        new TrainTicketModel()
        );

    accomodationModel = new BehaviorSubject<AccomodationModel>(
        new AccomodationModel()
        );
    
    travelcostModel = new BehaviorSubject<TravelCost>(
        new TravelCost()
        );
    
    remarksModel = new BehaviorSubject<RemarksModel>(
        new RemarksModel()
        );
    
    subscribeDetailVariables(observerTRFModel: Observer<TRFModel>, observerFlightModel: Observer<FlightTicketModel>, observerTrainModel: Observer<TrainTicketModel>, observerAccomodationModel: Observer<AccomodationModel>  ) {
    this.trfModel.subscribe(observerTRFModel);
    this.flightModel.subscribe(observerFlightModel);
    this.trainModel.subscribe(observerTrainModel);
    this.accomodationModel.subscribe(observerAccomodationModel);

}

    constructor(private apiService: MapAPIService) { } 


    getPostData(trfmodel: TRFModel,flightmodel:FlightTicketModel,trainmodel:TrainTicketModel,accomodation:AccomodationModel) {
        var jsonPostData = {
            "first_name":trfmodel.first_name,
            "middle_name": trfmodel.middle_name,
            "last_name": trfmodel.last_name,
            "employeeid": trfmodel.employeeid,
            "department": trfmodel.department,
            "designation": trfmodel.designation,
            "dob": trfmodel.dob,
            "gender":trfmodel.gender,
            "mobile":trfmodel.mobile,
            "passport_number":trfmodel.passport_number,
            "region":trfmodel.region,
            "event_location":trfmodel.event_location,
            "travel_purpose":trfmodel.travel_purpose,
            "event_name":trfmodel.event_name,
            "event_date":trfmodel.event_date,
            "user": trfmodel.userid,
            "approver":trfmodel.approver,
            "recommendation":trfmodel.recommendation,
            "flightdetail":flightmodel,
            "traindetail":trainmodel,
            "accomodationdetail":accomodation,



        }
        return jsonPostData;
    }

    updatedata(id:string,trfmodel: TRFModel,flightmodel:FlightTicketModel,trainmodel:TrainTicketModel,accomodation:AccomodationModel) {
        let d: IApiServiceDelegate = {
            Data: this.getPostData(trfmodel,flightmodel,trainmodel,accomodation),
            Method: "PATCH",
            Action:MapAPICollection.LEAD + '/' + id,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Travel Requisition Updated', 'primary');
                this.parentFormDetail.updated()
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error'+e, 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getFormDetail(formid: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.LEAD + '/' + formid,
            Success: (e: any) => {
                this.trfModel.next(e);
                this.flightModel.next(e.flightdetail);
                this.trainModel.next(e.traindetail);
                this.accomodationModel.next(e.accomodationdetail);
                this.travelcostModel.next(e.travelcostdetail);
                this.remarksModel.next(e.remarkdetail);
                this.parentFormDetail.detailRecive();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    

  

   
}