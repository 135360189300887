<app-topbar></app-topbar>
<section class="checkout standard-text mt-4 pv-100 w3_bg">
  <div class="container w3_bg">
    
    <div class="row justify-content-center checkout__forms mt-3 r-gap-40 w3_bg">
      <div class="col-lg-10 w3_bg">
        <div class="accordion" id="accordionExample" >
          <div *ngIf="nodataFound" class="noForm">
            <p>
              No Form Submitted !
            </p>


          </div>
          <div class="accordion-item mb-4 shadow-sm" *ngFor="let trfmodel of all_forms">
            <h2 class="accordion-header" id="heading{{trfmodel.id}}">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse{{trfmodel.id}}"
                aria-expanded="false" aria-controls="collapse{{trfmodel.first_name}}">
                <i class="fas fa-address-card">
                  {{trfmodel.id}} {{trfmodel.first_name}} Traveler</i>
              </button>
            </h2>
            <div id="collapse{{trfmodel.id}}" class="accordion-collapse collapse show" aria-labelledby="heading{{trfmodel.id}}"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="name" id="first_name" [(ngModel)]="trfmodel.first_name" name="first_name"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="first_name">First Name</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="middle_name" [(ngModel)]="trfmodel.middle_name" name="middle_name"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="middle_name">Middle Name</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="last_name" [(ngModel)]="trfmodel.last_name" name="last_name"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="last_name">Last Name</label>
                    </mdb-form-control>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="employee_id" [(ngModel)]="trfmodel.employeeid" name="employee_id"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="employee_id">Employee ID</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="department" [(ngModel)]="trfmodel.department" name="department"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="department">Department
                      </label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="designation" [(ngModel)]="trfmodel.designation" name="designation"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="designation">Designation</label>
                    </mdb-form-control>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="dob" [(ngModel)]="trfmodel.dob" name="dob" class="form-control" />
                      <label mdbLabel class="form-label" for="dob">D.O.B</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-2"> -->
                    <select class="form-select" aria-label="Default select example" [(ngModel)]="trfmodel.gender">
                      <option selected>Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.mobile" id="mobile" type="text"
                        name="mobile" />
                      <label mdbLabel class="form-label" for="mobile">Mobile</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.passport_number" id="passport-number"
                        name="passport-number" />
                      <label mdbLabel class="form-label" for="passport-number">Passport number</label>
                    </mdb-form-control>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example" [(ngModel)]="trfmodel.region">
                      <option selected>Region</option>
                      <option value="NA">NA</option>
                      <option value="EU">EU</option>
                      <option value="ME">ME</option>
                      <option value="APAC">APAC</option>
                      <option value="ANZ">ANZ</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_location" id="event-loc"
                        name="eventlocation" />
                      <label mdbLabel class="form-label" for="event-loc">Event Location</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example"
                      [(ngModel)]="trfmodel.travel_purpose">
                      <option selected>Purpose of travel</option>
                      <option value="sales">Sales</option>
                      <option value="internal">Internal</option>
                      <option value="event">Event</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_name" id="eventname"
                        name="eventname" />
                      <label mdbLabel class="form-label" for="eventname">Customer/Event name</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.approver" id="approver" name="approver"
                        type="email" />
                      <label mdbLabel class="form-label" for="approver">Approver</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_date" id="eventdate"
                        name="eventdate" type="date" />
                      <label mdbLabel class="form-label" for="eventdate">Event Date</label>
                    </mdb-form-control>
                  </div>
                </div>
              </div>
            </div>

            <div class="col d-flex justify-content-center pb-4" *ngIf="trfmodel.is_approved==false && trfmodel.is_disapproved==false">
                <button id="form_submit" type="button" class="btn btn-primary" (click)="onEditClick(trfmodel.id)">
                  Edit 
                </button>
            </div>
            <div class="col d-flex justify-content-center pb-4" *ngIf="trfmodel.is_approved==true || trfmodel.is_disapproved==true">
              Can not update this form !
          </div>
          </div>
         

          
        </div>
      </div>
    </div>
    
  </div>
</section>