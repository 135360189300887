<!-- Top bar -->
<!-- <app-topbar [headline]="headline" [tagline]="tagline"></app-topbar> -->

<!-- Page Heading -->
<!-- <h1 class="h3 mb-2 text-gray-800">Dashboard</h1>

<p class="mb-4">Travel Requisition Forms.</p> -->
<div class="row pt-3">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4 ">
        <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Total
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {{ data.total }}
                        </div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Rejected Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            Rejected
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {{ data.rejected }}
                        </div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-plane-slash fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Approved Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Approved
                        </div>
                        <div class="row no-gutters align-items-center">
                            <div class="col-auto">
                                <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                    {{ data.approved }}
                                </div>
                            </div>
                            <div class="col">
                                <div class="progress progress-sm mr-2">
                                    <div class="progress-bar bg-success" role="progressbar" style="width: 50%"
                                        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                            Pending Requests
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {{ data.pending }}
                        </div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-comments fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3 filters">
        <h6 class="m-0 font-weight-bold text-primary mt-2">Submissions</h6>
        <div style="width: 200px">
            <select class="form-select form-select-sm" (change)="getData($event)">
                <option  value="true">Approved</option>
                <option value="false">Disapproved</option>
                <option selected value="all">All</option>
            </select>
        </div>
    </div>
    <div class="card-body p-0 m-0" style="overflow: auto; height:50vh;white-space: nowrap;">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead class="thead-dark">
                    <tr>
                        <th>Submitter</th>
                        <th>Approver</th>
                        <th>Is Full Filled</th>
                        <th>Is Approved</th>
                       
                        <th>Travel Cost</th>
                        <th>Accommodation Cost</th>
                        <th>Total Cost</th>
                    </tr>
                </thead>
                <!-- <tfoot>
                    <tr>
                        <th>Name</th>
                        <th>Approver</th>
                        <th>Is Full Filled</th>
                        <th>Total Cost</th>
                        <th>Travel Cost</th>
                        <th>Accomodation Cost</th>
                    </tr>
                </tfoot> -->
                <tbody *ngIf="all_forms">
                    <tr *ngFor="let form of all_forms">
                        <td>
                            <a href="/dashboard/submission/{{ form.id }}">{{ form.first_name }} {{ form.last_name }}</a>
                        </td>

                        <td>{{ form.approver }}</td>
                        <td style="text-align: center;"> <i class="fas fa-check-circle text-success" *ngIf="form.is_fullfilled == true"></i> <i *ngIf="!form.is_fullfilled  == true" class="fas fa-times-circle text-danger"></i> </td>
                        <td style="text-align: center;"> <i *ngIf="form.is_approved  == true" class="fas fa-check-circle text-success"></i> <i  *ngIf="form.is_disapproved  == false && form.is_approved  == false" class="fas fa-times-circle text-gray"></i> <i  *ngIf="form.is_disapproved  == true" class="fas fa-times-circle text-danger"></i></td>
                       
                        <td>
                            {{ form.travelcostdetail ? form.travelcostdetail.travelcost : 0 }}
                        </td>

                        <td>
                            {{
                            form.travelcostdetail
                            ? form.travelcostdetail.accomodationcost
                            : 0
                            }}
                        </td>
                        <td>
                            {{ form.travelcostdetail ? form.travelcostdetail.totalcost : 0 }}
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!all_forms">
                    <tr>
                        <td  colspan="6" style="text-align: center; font-weight: bold;"> No Data Found. </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-spinner *ngIf="isLoading"></app-spinner> 