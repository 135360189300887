import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormsViewModel } from './models/form-view-model';
import { MapCommon } from '../app-providers/trf-base/MapCommon';
import { TRFResponseModel } from '../travel-requisition/models/travel-requisition-model';
import { BehaviorSubject } from 'rxjs';
import { UIChangerService } from '../app-providers/services/ui-changer.service';



@Component({
  selector: 'app-form-listing',
  templateUrl: './form-listing.component.html',
  styleUrls: ['./form-listing.component.scss'],
})
export class FormListingComponent implements OnInit {
  approved:string = 'true';
  all_forms = [];

  trfResponseModel = new BehaviorSubject<TRFResponseModel>(
    new TRFResponseModel()
  );

  isSubmissionPage: boolean = false;

  constructor( private vm: FormsViewModel,private router: Router, 
    private uiChangerService: UIChangerService
  ) {
    const localStorage_user = MapCommon.getloggedInUser();
    // this.vm.getforms(this.approved,'form-listing');
    // if(localStorage_user.is_superuser==true){
    // }else{
    //   this.router.navigate(['/travelform']);
    // }

   }
   
  ngOnInit(): void {
    // this.vm.parentForms = this
    // this.vm.subscribeDetail(this.trfResponseModel);
    this.subscribeToUIChangerService();
  }



  detailRecived(){
    this.all_forms = this.trfResponseModel.value.results;
   
  } 

  subscribeToUIChangerService(){
    this.uiChangerService.isSubmissionPage.subscribe( show => {
      this.isSubmissionPage = show;
    })
  }
  

}
