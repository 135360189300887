<app-topbar></app-topbar>
<section class="checkout standard-text mt-5 pv-100 w3_bg">
  
  <form #travellForm="ngForm"  (ngSubmit)="submit()" > 
  <div class="container w3_bg">
    <div class="row justify-content-center checkout__forms mt-3 r-gap-40 w3_bg">
      <!-- <div class="col-10">
        <p>
          A Travel Requisition Form is what employees use to ask for permission
          to go on a work trip. It collects details like the purpose,
          destination, dates, and estimated expenses, helping the company decide
          if the trip is okay in terms of rules and budget.
        </p>
      </div> -->
      <div class="col-lg-10 w3_bg">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                <i class="fas fa-address-card me-1"> </i>  Mandatory Information of The Traveler
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="name" id="first_name" [(ngModel)]="trfmodel.first_name" name="firstame" #firstame="ngModel" required
                        class="form-control" />
                      <label mdbLabel class="form-label" for="first_name">First Name*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                                *ngIf="(firstame.touched || travellForm.submitted) && firstame.errors?.required">
                                First Name is required
                  </p>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="middle_name" [(ngModel)]="trfmodel.middle_name" name="middleName" #middleName="ngModel" 
                        class="form-control" />
                      <label mdbLabel class="form-label" for="middle_name">Middle Name</label>
                    </mdb-form-control>
                    
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="last_name" [(ngModel)]="trfmodel.last_name" name="lastName" #lastName="ngModel" required
                        class="form-control" /> 
                      <label mdbLabel class="form-label" for="last_name">Last Name*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                                *ngIf="(lastName.touched || travellForm.submitted) && lastName.errors?.required">
                                Last Name is required
                  </p>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="employee_id" [(ngModel)]="trfmodel.employeeid" name="employeeId" #employeeId="ngModel" required
                        class="form-control" />
                      <label mdbLabel class="form-label" for="employee_id">Employee ID*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(employeeId.touched || travellForm.submitted) && employeeId.errors?.required">
                    Employee ID is required
                  </p>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="department" [(ngModel)]="trfmodel.department" name="department" required #department="ngModel"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="department">Department* </label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(department.touched || travellForm.submitted) && department.errors?.required">
                    Department is required
                  </p>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="designation" [(ngModel)]="trfmodel.designation" name="designation" required #designation="ngModel"
                        class="form-control" required />
                      <label mdbLabel class="form-label" for="designation">Designation*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(designation.touched || travellForm.submitted) && designation.errors?.required">
                    Designation is required
                  </p>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input style="display:none" mdbInput />
                      <input   type="date" id="dob" [(ngModel)]="trfmodel.dob"  [ngClass]="{'active': trfmodel.dob !== null || trfmodel.dob !== undefined}" #dob="ngModel" required name="dob" class="form-control" />
                      <label mdbLabel class="form-label" for="dob">D.O.B*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(dob.touched || travellForm.submitted) && dob.errors?.required">
                    D.O.B is required
                  </p>
                  </div>
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-2"> -->
                    <select class="form-select mb-2" [(ngModel)]="trfmodel.gender" name="gender" #gender="ngModel" required aria-label="Default select example">
                      <option selected  value="undefined"> Gender*</option>
                      <option *ngFor="let gen of genderList"  value={{gen.id}}> {{gen.name}}</option>
                    </select>
                    <p class="text-danger errorTxt"
                    *ngIf="(gender.touched || travellForm.submitted) && gender.errors?.required">
                    Gender is required
                  </p>
                    <!-- </mdb-form-control> -->
                  </div>
                   
                </div>

                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.mobile" required #mobile="ngModel"  id="mobile" type="text"
                        name="mobile" />
                      <label mdbLabel class="form-label" for="mobile">Mobile*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(mobile.touched || travellForm.submitted) && mobile.errors?.required">
                    Mobile is required
                  </p>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.passport_number" required #passportNumber="ngModel"  id="passport-number"
                        name="passportNumber" />
                      <label mdbLabel class="form-label" for="passport-number">Passport number*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(passportNumber.touched || travellForm.submitted) && passportNumber.errors?.required">
                    Passport number is required
                  </p>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select mb-2" aria-label="Default select example" name="region" required #region="ngModel" [(ngModel)]="trfmodel.region">
                      <option selected value="undefined">Region*</option>
                      <option value="US">US</option>
                      <option value="EU">EU</option>
                      <option value="ME">ME</option>
                      <option value="APAC">APAC</option>
                      <option value="ANZ">ANZ</option>
                    </select>
                    <!-- </mdb-form-control> -->
                    <p class="text-danger errorTxt"
                    *ngIf="(region.touched || travellForm.submitted) && region.errors?.required">
                    Region is required
                  </p>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.approver" #approver="ngModel" required id="approver"
                        name="approver" type="email" />
                      <label mdbLabel class="form-label" for="approver">Approver Email*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(approver.touched || travellForm.submitted) && approver.errors?.required">
                    Approver Email name is required
                  </p>
                  </div>
                 
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select mb-2" aria-label="Default select example" name="travelPurpose" required #travelPurpose="ngModel" (change)="onTravelPurposeChange($event)" [(ngModel)]="trfmodel.travel_purpose">
                      <option selected value="undefined">Purpose of travel*</option>
                      <option value="sales">Sales</option>
                      <option value="internal">Internal</option>
                      <option value="event">Event</option>
                    </select>
                    <!-- </mdb-form-control> -->
                    <p class="text-danger errorTxt"
                    *ngIf="(travelPurpose.touched || travellForm.submitted) && travelPurpose.errors?.required">
                    Purpose of travel is required
                  </p>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_name" [disabled]="trfmodel.travel_purpose == 'sales' || trfmodel.travel_purpose == 'internal'" #eventname="ngModel" required id="eventname"
                        name="eventname" />
                      <label mdbLabel class="form-label" for="eventname">Customer/Event name*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(eventname.touched || travellForm.submitted) && eventname.errors?.required">
                    Customer/Event name is required
                  </p>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control"   [(ngModel)]="trfmodel.event_location" [disabled]="trfmodel.travel_purpose == 'sales' || trfmodel.travel_purpose == 'internal'" required #eventlocation="ngModel" id="event-loc"
                        name="eventlocation" />
                      <label mdbLabel class="form-label" for="event-loc">Event Location*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(eventlocation.touched || travellForm.submitted) && eventlocation.errors?.required">
                    Event location is required
                  </p>
                  </div>
                  
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_date"  [disabled]="trfmodel.travel_purpose == 'sales' || trfmodel.travel_purpose == 'internal'"  #eventdate="ngModel" required id="eventdate"
                        name="eventdate" type="date" />
                      <label mdbLabel class="form-label" for="eventdate">Event Date*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt" 
                    *ngIf="(eventdate.touched || travellForm.submitted) && eventdate.errors?.required">
                    Event Date name is required
                  </p>
                  </div>
                </div>

                <div class="row justify-content-start align-items-center mt-2">
                  <div class="col-md-4 align-self-center">
                    <div class="form-check form-check-inline">
                      <input mdbRadio class="form-check-input" type="radio" id="inlineRadio1" required name="inlineRadioOptions" #travel  [(ngModel)]="inlineRadioOptions"
                        value="travel" (click)="hide_show(travel.value)">
                      <label class="form-check-label" for="inlineRadio1">Travel</label>
                     
                    </div>
                    <div class="form-check form-check-inline">
                      <input mdbRadio class="form-check-input" id="inlineRadio2" type="radio" required name="inlineRadioOptions" #travelandstay  [(ngModel)]="inlineRadioOptions"
                        value="travelandstay errorTxt" (click)="hide_show(travelandstay.value)">
                      <label class="form-check-label" for="inlineRadio2">Travel & Stay</label>
                      
                    </div>
                    <p class="text-danger errorTxt"
                    *ngIf="(inlineRadioOptions == undefined && travellForm.submitted)">
                    Travel/Stay is required
                  </p>
                  </div>
                  <!-- <div class="col-md-4" #mode_of_travel *ngIf="mode_of_travel">
                    <select #mode_of_travel_options class="form-select mb-2" name="modeOFTravel"  [(ngModel)]="modeOfTravel"  #modeOFTravel required  (change)="show_mode_of_travel_options(mode_of_travel_options.value)">
                      <option selected value="undefined">Mode of Travel</option>
                      <option value="train">By Road</option>
                      <option value="flight">By Air</option>
                    </select>
                    <p class="text-danger errorTxt"
                    *ngIf="(modeOfTravel == undefined && travellForm.submitted) ">
                    Mode of Travel is required
                  </p>
                  </div> -->
                </div>

              </div>
            </div>
          </div>
          <div #flight_accordion *ngIf="flight_accordion" class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <i class="fas fa-plane me-1"> </i> For Flight Tickets
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-6 align-self-center">
                    <div class="form-check form-check-inline">
                      <input mdbRadio class="form-check-input" type="radio" (change)="travelSideChange($event)" required name="travelType" id="inlineRadio3"  [(ngModel)]="travelType"
                        value="oneWay" >
                      <label class="form-check-label" for="inlineRadio3">One Way</label>
                    </div>
                  </div>
                  <div class="col-md-6 align-self-center">
                    <div class="form-check form-check-inline">
                      <input mdbRadio class="form-check-input" type="radio" required name="travelType" id="inlineRadio4"  [(ngModel)]="travelType"
                        value="roundTrip" >
                      <label class="form-check-label" for="inlineRadio4">Round Trip</label>
                    </div>
                  </div>
                  
                </div>
                <div class="row mb-2 position-relative">
                  <div class="col-md-6 position-relative z-3">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="destination" name="destination" class="form-control" required #destination="ngModel"
                        [(ngModel)]="flightmodel.destination" />
                      <label mdbLabel class="form-label" for="destination">From
                        (Delhi Airport India)*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(destination.touched || travellForm.submitted) && destination.errors?.required">
                    From (City,place) is required
                  </p>
                  </div>
                  <i class="fas fa-exchange-alt position-absolute  text-center z-1" style="top:10px"></i>
                  <div class="col-md-6 position-relative z-3">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="return_destination" (change)="returnDestinationChange($event)" name="returnDestination" class="form-control" required  #returnDestination="ngModel"
                        [(ngModel)]="flightmodel.return_destination" />
                      <label mdbLabel class="form-label" for="return_destination">To
                        (Bengaluru International Airport India)*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(returnDestination.touched || travellForm.submitted) && returnDestination.errors?.required">
                    To (city,place) is required
                  </p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="date_of_travel" name="dateOfTravel" class="form-control" required #dateOfTravel="ngModel"
                        [(ngModel)]="flightmodel.traveldate" />
                      <label mdbLabel class="form-label" for="date_of_travel">Departure (Date)*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(dateOfTravel.touched || travellForm.submitted) && dateOfTravel.errors?.required">
                    Departure is required
                  </p>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="return_date_of_travel" [disabled]="travelType == 'oneWay'" required name="returnDateOfTravel" class="form-control"  #returnDateOfTravel="ngModel"
                        [(ngModel)]="flightmodel.returndate" />
                      <label mdbLabel class="form-label" for="return_date_of_travel">Return (Date)*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(returnDateOfTravel.touched || travellForm.submitted) && returnDateOfTravel.errors?.required">
                    Return is required
                  </p>
                  </div>
                  
                </div>
                <div class="row mb-2">
                
                    
                    <div class="col-md-4">

                      <ng-select [(ngModel)]="preferedStartTime" id="preferedtime" class="header-imput w-100"
                      placeholder="Prefere Start Time"  name="preferedstarttime" >
                        <ng-option *ngFor="let o of TimeSelections" [value]="o['time_display']">{{o['time_display']}} </ng-option>
                      </ng-select> 
                    </div>

                    <div class="col-md-4">

                      <ng-select [(ngModel)]="preferedEndTime" id="preferedtime" class="header-imput w-100"
                      placeholder="Prefere End Time" name="preferedendtime" >
                        <ng-option *ngFor="let o of TimeSelections" [value]="o['time_display']">{{o['time_display']}} </ng-option>
                      </ng-select> 
                    </div>
                    <div class="col-md-2">
                      <button type="button" (click)="addTime()" class="btn btn-primary " [disabled]="preferedEndTime && preferedStartTime ?false:true">
                        ADD
                      </button>
                    </div>
               
                 
                </div>
                <div class="row mb-2">
                  <div class="col-md-12">

                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="prefered_time" [disabled]="true"  name="preferedtime" class="form-control"     [(ngModel)]="selectedTime" [value]="selectedTime" />
                      <label mdbLabel class="form-label" for="prefered_time">Selected Prefered Time</label>
                    </mdb-form-control>
                  </div>

                </div>
                <div class="row mb-2">
                  


                </div>
                <div class="row mb-2">
                
                  <div class="col-md-12">
                    
                    <ng-select class="mb-2" aria-label="Default select example" required name="seat_preference" #seatpreference="ngModel"
                      [(ngModel)]="flightmodel.seat_preference" placeholder="Select Seat Preference*">
                      <ng-option *ngFor="let o of seatChoices" [value]="o['id']">{{o['name']}} </ng-option>
                    </ng-select>
                   
                    <p class="text-danger errorTxt"
                    *ngIf="(seatpreference.touched || travellForm.submitted) && seatpreference.errors?.required">
                    Seat preference is required
                  </p>
                  </div>
                 
                </div>
                
                <div class="row mb-2">
                
                  <div class="col-md-12">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select mb-2" aria-label="Default select example" required name="travelclass" #travelclass="ngModel"
                      [(ngModel)]="flightmodel.travelclass">
                      <option selected value="undefined">Travel Class*</option>
                      <option value="Business">Business</option>
                      <option value="Economy">Economy</option>
                      <option value="Premium Economy">Premium Economy</option>
                    </select>
                    <!-- </mdb-form-control> -->
                    <p class="text-danger errorTxt"
                    *ngIf="(travelclass.touched || travellForm.submitted) && travelclass.errors?.required">
                    Travel Class is required
                  </p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <!-- <div #train_accordion *ngIf="train_accordion" class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <i class="fas fa-subway me-1"></i>  For Train Tickets 
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2 position-relative">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="tr_from_station_name" class="form-control" required name="fromstation" #fromstation="ngModel"
                        [(ngModel)]="trainmodel.fromstation" />
                      <label mdbLabel class="form-label" for="tr_from_station_name">From (Station Name)</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(fromstation.touched || travellForm.submitted) && fromstation.errors?.required">
                    From (Station Name)
                  </p>
                  </div>
                  <i class="fas fa-exchange-alt position-absolute  text-center" style="top:10px"></i>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="tr_to_station_name" class="form-control" required name="tostation" #tostation="ngModel"
                        [(ngModel)]="trainmodel.tostation" />
                      <label mdbLabel class="form-label" for="tr_to_station_name">To (Station Name)</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(tostation.touched || travellForm.submitted) && tostation.errors?.required">
                    To (Station Name)
                  </p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="tr_date_of_journey" class="form-control" required name="trDateOfJourney" #trDateOfJourney="ngModel"
                        [(ngModel)]="trainmodel.startdate" />
                      <label mdbLabel class="form-label" for="tr_date_of_journey">Date of Journey</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(trDateOfJourney.touched || travellForm.submitted) && trDateOfJourney.errors?.required">
                    Date of Journey is required
                  </p>
                  </div>

                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="tr_train_name_or_number" class="form-control" required name="trTrainNameOrNumber" #trTrainNameOrNumber="ngModel"
                        [(ngModel)]="trainmodel.train" />
                      <label mdbLabel class="form-label" for="tr_train_name_or_number">Train Name / Number</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(trTrainNameOrNumber.touched || travellForm.submitted) && trTrainNameOrNumber.errors?.required">
                    Train Name / Number is required
                  </p>
                  </div>
                  <div class="col-md-4">
                    
                    <select class="form-select mb-2" aria-label="Default select example" required name="ticketclass" #ticketclass="ngModel"
                      [(ngModel)]="trainmodel.ticketclass">
                      <option selected value="undefined">Class</option>
                      <option value="1A">1A</option>
                      <option value="2A">2A</option>
                      <option value="3A">3A</option>
                    </select>
                    <p class="text-danger errorTxt"
                    *ngIf="(ticketclass.touched || travellForm.submitted) && ticketclass.errors?.required">
                    Class is required
                  </p>
                    
                  </div>
                </div>
                
              </div>
            </div>
          </div> -->
          <div #accommodation *ngIf="accommodation" class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <i class="fas fa-hotel me-1"> </i> For Accommodation 
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <!-- <mdb-form-control class="mb-4"> -->
                   
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="tr_to_station_name" class="form-control" required name="location" #location="ngModel"
                      [(ngModel)]="accomodation.location" />
                      <label mdbLabel class="form-label" for="tr_to_station_name">Location (City, Property Name Or Location)*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(location.touched || travellForm.submitted) && location.errors?.required">
                    Location
                  </p>
                    <!-- </mdb-form-control> -->
                  </div>
                  <div class="col-md-6">
                   
                    <select class="form-select mb-2" aria-label="Default select example" required name="occupancy" #occupancy="ngModel"
                      [(ngModel)]="accomodation.occupancy">
                      <option selected value="undefined">Occupancy*</option>
                      <option value="Single">Single</option>
                      <option value="Double">Double</option>
                    </select>
                    <p class="text-danger errorTxt"
                    *ngIf="(occupancy.touched || travellForm.submitted) && occupancy.errors?.required">
                    Occupancy
                  </p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mt-2">
                      <input mdbInput type="date" id="htl_date_of_check_in" class="form-control" required name="htlDateOfCheckIn" #htlDateOfCheckIn="ngModel"
                        [(ngModel)]="accomodation.checkin" />
                      <label mdbLabel class="form-label" for="htl_date_of_check_in">Check-In (Date)*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(htlDateOfCheckIn.touched || travellForm.submitted) && htlDateOfCheckIn.errors?.required">
                    Check-In
                  </p>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mt-2">
                      <input mdbInput type="date" id="htl_date_of_check_out" class="form-control" required name="htlDateOfCheckOut" #htlDateOfCheckOut="ngModel"
                        [(ngModel)]="accomodation.checkout" />
                      <label mdbLabel class="form-label" for="htl_date_of_check_out">Check-Out (Date)*</label>
                    </mdb-form-control>
                    <p class="text-danger errorTxt"
                    *ngIf="(htlDateOfCheckOut.touched || travellForm.submitted) && htlDateOfCheckOut.errors?.required">
                    Check-Out
                  </p>
                  </div>
                </div>
                <div class="row mb-2">
                
                  <div class="col-md-6">
                    
                    <ng-select class="mb-2" aria-label="Default select example" required name="meal_preference" #mealpreference="ngModel"
                      [(ngModel)]="accomodation.meal_preference" placeholder="Select Meal Preference">
                      <ng-option *ngFor="let o of mealChoices" [value]="o.id">{{o.name}} </ng-option>
                    </ng-select>
                   
                    <!-- <p class="text-danger errorTxt"
                    *ngIf="(mealpreference.touched || travellForm.submitted) && mealpreference.errors?.required">
                    Seat preference is required
                  </p> -->
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="dietary_restriction" class="form-control" name="dietary_restriction" #dietaryrestriction="ngModel"
                      [(ngModel)]="accomodation.dietary_restriction" />
                      <label mdbLabel class="form-label" for="dietary_restriction">Dietary Restriction</label>
                    </mdb-form-control>

                  </div>
                 
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <!-- <mdb-form-control class="mb-4"> -->
                   
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="preferedlocation" class="form-control" name="preferedlocation" #location="ngModel"
                      [(ngModel)]="accomodation.preferedlocation" />
                      <label mdbLabel class="form-label" for="preferedlocation">Prefered Location</label>
                    </mdb-form-control>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row r-gap-40 my-5">
      <div class="col d-flex justify-content-center">
        <button id="form_submit" type="submit" class="btn btn-primary" >Submit</button>
        <!-- [disabled]="!travellForm.form.valid" -->
      </div>
    </div>
  </div>
</form>
<!-- <h2 class="text-center" *ngIf="this.profileResponseModel.value.results?.length == 0"> Please ensure your profile is completed before proceeding to fill out the travel request form. </h2> -->
</section>

<app-spinner *ngIf="isLoading"></app-spinner> 