<div class="d-flex align-items-center  border-bottom pb-2">
    <h6>

        <i class="fas fa-train me-2 section-icon"></i> <span class="section-heading  fw-bold">Train Details</span> 
    </h6>
     
    <div class="col-6 d-flex justify-content-end">
        <div>
            <a class="btn btn-sm collapse-btn shadow-none" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTrain" aria-expanded="false" aria-controls="collapseTrain">
                <i class="fas fa-angle-down me-1"> </i> 
            </a>
        </div>
     </div>


</div>
<div class="collapse mt-2" id="collapseTrain">
    <div class="col-12">
        <hr class="mt-0">
    </div>
    <ul class="p-0">
        <li class="d-flex justify-content-between">
            <div class="w-50 mb-0 fw-bold">
                Date of Journey
            </div>
            <div class="w-50 text-capitalize">
                <span class="me-2">:</span>{{parentTrain.startdate ?( parentTrain.startdate | date : "MMMM d, yyyy"): 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-50 mb-0 fw-bold">
                Train Name / Number
            </div>
            <div class="w-50 text-capitalize">
                <span class="me-2">:</span>{{parentTrain.train ? parentTrain.train : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-50 mb-0 fw-bold">
                Class
            </div>
            <div class="w-50 text-capitalize">
                <span class="me-2">:</span>{{parentTrain.ticketclass ? parentTrain.ticketclass : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-50 mb-0 fw-bold">
                From (Station Name)
            </div>
            <div class="w-50 text-capitalize">
                <span class="me-2">:</span>{{parentTrain.fromstation ? parentTrain.fromstation : 'NA' }}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-50 mb-0 fw-bold">
                To (Station Name)
            </div>
            <div class="w-50 text-capitalize">
                <span class="me-2">:</span>{{parentTrain.tostation ? parentTrain.tostation : 'NA'}}
            </div>
        </li>
    </ul>
</div>