import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ApproveFormComponent } from './approve-form/approve-form.component';
import { FormListingComponent } from './form-listing/form-listing.component';
import { FormDetailComponent } from './form-listing/form-detail/form-detail.component';
import { TravelRequisitionComponent } from './travel-requisition/travel-requisition.component';
import { TrfListComponent } from './trf-list/trf-list.component';
import { TrfEditComponent } from './trf-edit/trf-edit.component';
import { ContentComponent } from './form-listing/main-content/content/content.component';
import { AllSubmissionsComponent } from './form-listing/main-content/all-submissions/all-submissions.component';
import { UserSubmissionsComponent } from './form-listing/main-content/user-submissions/user-submissions.component';
import { SubmissionComponent } from './form-listing/main-content/submission/submission.component';
import { ProfileComponent } from './form-listing/main-content/profile/profile.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'travelform', component: TravelRequisitionComponent },
  { path: 'approve-form', component: ApproveFormComponent },
  { path: 'forms', component: FormListingComponent },
  { path: 'forms/:id', component: FormDetailComponent },
  { path: 'trflist', component: TrfListComponent },
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: 'dashboard',
    component: FormListingComponent,
    children: [
      { path: '', component: ContentComponent, },
      { path: 'submission', component: SubmissionComponent },
      { path: 'submission/:id', component: SubmissionComponent },
      { path: 'all-submissions', component: AllSubmissionsComponent },
      { path: 'user-submissions', component: UserSubmissionsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'submission/edit/:id', component: TrfEditComponent },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
