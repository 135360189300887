import { Component, OnInit,Input } from '@angular/core';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { FormsViewModel } from 'src/app/form-listing/models/form-view-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  @Input() parentTrfData:any={};
  currentUser: any;
  base_app_url = environment.base_app_url
  constructor(private vm: FormsViewModel) {
    const localStorage_user = MapCommon.getloggedInUser();
    this.currentUser = localStorage_user;
    // console.log('currentUser', this.currentUser);
  }

  ngOnInit(): void {
  }

}
