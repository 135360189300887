<div class="d-flex align-items-center justify-content-between pb-2 ">
    <h6>
        <i class="fas fa-calendar me-2 section-icon"></i> <span class="section-heading fw-bold">Event Details</span>

    </h6>
    
    <div class="col-6 d-flex justify-content-end">
        <div>
            <a class="btn btn-sm collapse-btn shadow-none" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseEvent" aria-expanded="false" aria-controls="collapseEvent">
            <i class="fas fa-angle-down me-1"> </i> 
            </a>
        </div>
     </div>
</div>

<div class="collapse mt-2" id="collapseEvent">

    <div class="col-12">
        <hr class="mt-0">
    </div>

    <ul class="p-0">
        <li class="d-flex justify-content-start">
            <div class="w-40">
                <h6 class="mb-0 fw-bold text-info">Request Approved</h6>
            </div>
            <div class="w-40">
                <div class="text-capitalize">
                    <span class="me-2">:</span> 
                    <i *ngIf="parentEventData.is_approved  == true" class="fas fa-check-circle text-success"></i>
                    <i *ngIf="parentEventData.is_disapproved  == false && parentEventData.is_approved  == false"
                        class="fas fa-times-circle text-gray"></i>
                    <i *ngIf="parentEventData.is_disapproved  == true" class="fas fa-times-circle text-danger"></i>
                </div>
            </div>
        </li>
        <li class="d-flex justify-content-start">
            <div class="w-40 mb-0 text-info fw-bold">
                Event Location
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentEventData.event_location ? parentEventData.event_location : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-start">
            <div class="w-40 mb-0 text-info fw-bold">
                Region
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentEventData.region ? parentEventData.region : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-start">
            <div class="w-40 mb-0 text-info fw-bold">
                Purpose of Travel
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentEventData.travel_purpose ? parentEventData.travel_purpose : 'NA' }}
            </div>
        </li>
        <li class="d-flex justify-content-start">
            <div class="w-40 mb-0 text-info fw-bold">
                Customer/Event name
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentEventData.event_name ? parentEventData.event_name : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-start">
            <div class="w-40 mb-0 text-info fw-bold">
                Approver
            </div>
            <div class="w-50 text-capitalize" style="word-break: break-all;">
                <span class="me-2">:</span>  <a href="mailto:{{parentEventData.approver ? parentEventData.approver : 'NA'}}"><i
                        class="fas fa-user"></i> {{parentEventData.first_name}} {{parentEventData.last_name}}</a>
            </div>
        </li>
        <li class="d-flex justify-content-start">
            <div class="w-40 mb-0 text-info fw-bold">
                Event Date
            </div>
            <div class="w-60 text-capitalize">
                <span class="me-2">:</span>{{parentEventData.event_date ? (parentEventData.event_date | date : "MMMM d, yyyy") : 'NA' }}
            </div>
        </li>
    </ul>
</div>