import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents-details',
  templateUrl: './documents-details.component.html',
  styleUrls: ['./documents-details.component.scss'],
})
export class DocumentsDetailsComponent implements OnInit {
  currentUser_is_superuser: boolean = false;
  base_api_url: any = environment.base_api_url;
  @Input() parentDocument: any = {};
  @Input() is_approved: any = {};
  @Output() fileEvent = new EventEmitter<any>();
  constructor() {
    const localStorage_user = MapCommon.getloggedInUser();
    this.currentUser_is_superuser = localStorage_user.is_superuser;
  }

  ngOnInit(): void { }

  fileUpload(event) {
    this.fileEvent.emit(event);
  }

  extractFileName(url: string) {
    // Use a regular expression to match the file name
    const match = url.match(/\/([^\/]+)$/);

    // Check if there's a match
    if (match && match[1]) {
      return match[1];
    } else {
      // If no match found, return an empty string or handle it as per your requirement
      return '';
    }
  }
}
