import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TRFResponseModel , CountModel} from 'src/app/travel-requisition/models/travel-requisition-model';
import { TRFViewModel } from './models/form-view-model';
import { Router } from '@angular/router';



@Component({
  selector: 'app-trf-list',
  templateUrl: './trf-list.component.html',
  styleUrls: ['./trf-list.component.scss']
})
export class TrfListComponent implements OnInit {
  all_forms=[];
  nodataFound:boolean=false;


  trfResponseModel = new BehaviorSubject<TRFResponseModel>(
    new TRFResponseModel()
  );

  constructor(private vm: TRFViewModel,private router: Router) { 
    this.vm.getforms();
  }

  detailRecived(){
    this.all_forms = this.trfResponseModel.value.results;
    this.nodataFound = this.vm.nodataFound
  }
  

  ngOnInit(): void {
    this.vm.parentForms = this
    this.vm.subscribeDetail(this.trfResponseModel);
    
  }

  onEditClick(id:string){
    this.router.navigate(['/trflist',id]);
    
  }
}
