import { Component, OnInit, ViewChild } from '@angular/core';
import { TRFViewModel } from './models/travel-requisition-view-model';
import { Router } from '@angular/router';
import { TRFModel, FlightTicketModel, TrainTicketModel, AccomodationModel, ProfileResponseModel, choiceModel } from './models/travel-requisition-model';
import { MapCommon } from '../app-providers/trf-base/MapCommon';
import { LoginUserModel } from '../login/models/login-model';
import { FormGroup } from '@angular/forms';
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-travel-requisition',
  templateUrl: './travel-requisition.component.html',
  styleUrls: ['./travel-requisition.component.scss']
})
export class TravelRequisitionComponent implements OnInit {
  genderList = [{ id:'M' , name:'Male'},{ id:'F' , name:'Female'}]
  @ViewChild('travellForm') travellForm:any
  profileResponseModel = new BehaviorSubject<ProfileResponseModel>(new ProfileResponseModel());
  timesData:any = [];
  selectedTime:string='';

  travelType:string = 'oneWay'
  flight_accordion: boolean = false;
  train_accordion: boolean = false;
  accommodation: boolean = false;
  mode_of_travel: boolean = false;
  trfmodel = new TRFModel()
  flightmodel = new FlightTicketModel()
  trainmodel = new TrainTicketModel()
  accomodation = new AccomodationModel()
  trForm = FormGroup;
  isLoading:boolean = false
  inlineRadioOptions:any
  modeOfTravel:any
  values:any=[] 
  selectedValues:[];
  preferedEndTime:string;
  preferedStartTime:string;
  selectedTimes:string;
  seatChoices:choiceModel[]=[];
  mealChoices:choiceModel[]=[];

  TimeSelections = [
    {time: '00:00:00', time_display: "12:00 AM"}, {time: '01:00:00', time_display: "01:00 AM"},
    {time: '02:00:00', time_display: "02:00 AM"}, {time: '03:00:00', time_display: "03:00 AM"},
    {time: '04:00:00', time_display: "04:00 AM"}, {time: '05:00:00', time_display: "05:00 AM"},
    {time: '06:00:00', time_display: "06:00 AM"}, {time: '07:00:00', time_display: "07:00 AM"},
    {time: '08:00:00', time_display: "08:00 AM"}, {time: '09:00:00', time_display: "09:00 AM"},
    {time: '10:00:00', time_display: "10:00 AM"}, {time: '11:00:00', time_display: "11:00 AM"},
    {time: '12:00:00', time_display: "12:00 PM"}, {time: '13:00:00', time_display: "01:00 PM"},
    {time: '14:00:00', time_display: "02:00 PM"}, {time: '15:00:00', time_display: "03:00 PM"},
    {time: '16:00:00', time_display: "04:00 PM"}, {time: '17:00:00', time_display: "05:00 PM"},
    {time: '18:00:00', time_display: "06:00 PM"}, {time: '19:00:00', time_display: "07:00 PM"},
    {time: '20:00:00', time_display: "08:00 PM"}, {time: '21:00:00', time_display: "09:00 PM"},
    {time: '22:00:00', time_display: "10:00 PM"}, {time: '23:00:00', time_display: "11:00 PM"},

  ];
  
  constructor(private vm: TRFViewModel, private router: Router) { 

    this.vm.travelRequisitionComponent = this
    const localStorage_user = MapCommon.getloggedInUser();
    this.trfmodel.first_name = localStorage_user.first_name
    this.trfmodel.last_name = localStorage_user.last_name
  

  }

  ngOnInit(): void {
    document.body.style.backgroundColor = "#fafafa";
    this.vm.subscribeProfileDetail(this.profileResponseModel)
    this.vm.getProfileDetails() 
    this.vm.getAllChoices();
    this.isLoading = true
  }

  // getFullItem(Time: any){
  //   var result = this.TimeSelections.filter(obj => {
  //     return obj.time === Time;
  //   })
  //   if (result[0]){

  //     return result[0]['time_display']
  //   }else{
  //     return ''
  //   }
  // };


  hide_show(val: string) {
    if (val == 'travel') {
      this.flight_accordion = true;
      this.accommodation = false;
    } else {
      this.flight_accordion = true;
      this.accommodation = true;
    }
  }

  travelSideChange(e){
    if(e){
      this.flightmodel.returndate = null
    }
  }

  onTravelPurposeChange(e) {
    if( this.trfmodel.travel_purpose == 'sales' || this.trfmodel.travel_purpose == 'internal'){
      this.trfmodel.event_name = undefined
      this.trfmodel.event_location = undefined
      this.trfmodel.event_date = undefined
    }
  }
 

  returnDestinationChange(e){
    if(e){
      this.accomodation.location = this.flightmodel.return_destination
    }
  }

  timeslotChange(e){
    
    if(e){
      e.forEach(element => {
        this.values.push(element.id);
      });
      this.flightmodel.preferedtime = e;
    }
  }
  addTime(){
    if (this.preferedStartTime && this.preferedEndTime){

      if (this.selectedTime && this.selectedTime != ''){
        this.selectedTime = this.selectedTime + ' ,' + this.preferedStartTime + ' - ' + this.preferedEndTime;
      }else{
        this.selectedTime = this.preferedStartTime + ' - ' + this.preferedEndTime;
      }
    }
    this.preferedEndTime = undefined;
    this.preferedStartTime = undefined;

  }


  DataRecieved(){
    this.isLoading = false

    if(this.profileResponseModel.value.results.length > 0){
    this.trfmodel.department =  this.profileResponseModel.value.results[0].department  ? this.profileResponseModel.value.results[0].department  : undefined
    this.trfmodel.employeeid = this.profileResponseModel.value.results[0].employeeid ?  String(this.profileResponseModel.value.results[0].employeeid) : undefined
    this.trfmodel.designation = this.profileResponseModel.value.results[0].designation ? this.profileResponseModel.value.results[0].designation : undefined
    this.trfmodel.dob = this.profileResponseModel.value.results[0].dob ? this.profileResponseModel.value.results[0].dob : undefined
    this.trfmodel.gender = this.profileResponseModel.value.results[0].gender ? this.profileResponseModel.value.results[0].gender : undefined
    this.trfmodel.mobile = this.profileResponseModel.value.results[0].mobile ?  String(this.profileResponseModel.value.results[0].mobile) : undefined
    this.trfmodel.passport_number = this.profileResponseModel.value.results[0].passport_number ? String(this.profileResponseModel.value.results[0].passport_number) : undefined
    this.trfmodel.approver = this.profileResponseModel.value.results[0].manager ?  this.profileResponseModel.value.results[0].manager :undefined
  }
  }

  choicesReceived(data){
    
    this.mealChoices = data.filter(item =>item['type'] =='meal_choices')
    this.seatChoices = data.filter(item =>item['type'] =='seat_choices')
    

    
  }

  show_mode_of_travel_options(value: string) {
    switch (value) {
      case 'train':
        this.flight_accordion = false;
        this.train_accordion = true;
        break;
      case 'flight':
        this.train_accordion = false;
        this.flight_accordion = true;
        break;

      default:
        this.train_accordion = false;
        this.flight_accordion = false;
        break;
    }
  }

  submit() {
    // console.log(this.travellForm ,this.trfmodel.approver , /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.trfmodel.approver) )
    if (this.trfmodel.approver && this.travellForm.form.valid && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.trfmodel.approver.trim()) ){
      this.isLoading = true
      this.trfmodel.userid = MapCommon.getloggedInUser().id;
      if (this.selectedTime)
      this.flightmodel.preferedtime = this.selectedTime;
      this.vm.postData(this.trfmodel, this.flightmodel, this.trainmodel, this.accomodation);
    } else if(this.trfmodel.approver && /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.trfmodel.approver.trim()) == false) { 
      document.getElementById('collapseOne')?.classList.add('show')
      AlertNotificationsComponent.alertCall.showAlert('Approver email is not valid, please correct !', 'danger');
    }
      else{
      document.getElementById('collapseTwo')?.classList.add('show')
      document.getElementById('collapseOne')?.classList.add('show')
      document.getElementById('collapseFour')?.classList.add('show')
      AlertNotificationsComponent.alertCall.showAlert('All fields are required', 'danger');
    }
  }

  dataPosted(){
    this.isLoading = false
    this.router.navigate(['/dashboard']);
  }

}
