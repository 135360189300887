import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TRFResponseModel } from 'src/app/travel-requisition/models/travel-requisition-model';
import { FormsViewModel } from '../../models/form-view-model';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';

@Component({
  selector: 'app-user-submissions',
  templateUrl: './user-submissions.component.html',
  styleUrls: ['./user-submissions.component.scss']
})
export class UserSubmissionsComponent implements OnInit {
  headline = 'User Submissions'
  tagline = 'User Submissions Short Description'
  approved: string = 'true';
  all_forms = [];
  data: any = {};
  isLoading:boolean = false
  trfResponseModel = new BehaviorSubject<TRFResponseModel>(
    new TRFResponseModel()
  );

  constructor(private vm: FormsViewModel, private router: Router) {
    
  }
  ngOnInit(): void {
    this.vm.userSubmissionsComponent = this;
    this.vm.subscribeAllSubDetail(this.trfResponseModel);
    const localStorage_user = MapCommon.getloggedInUser();
    this.vm.getforms("all", 'userSubmission','true');
    this.isLoading = true
  }



  detailRecived() {
    this.all_forms = this.trfResponseModel.value.results;
    this.isLoading = false
  }

}
