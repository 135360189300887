<nav class="navbar navbar-expand-md navbar-light fixed-top bg-white">
    <div class="container">
      <a class="navbar-brand" routerLink="/dashboard">
        <span class="me-1"><i class="fas fa-umbrella-beach"></i> </span><span>Travel Desk</span>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
              <i class="fas fa-tachometer-alt me-1"></i> Dashboard
            </a>
          </li>
          <li class="nav-item" *ngIf="currentUser_is_superuser == true">
            <a class="nav-link" routerLink="/dashboard/all-submissions" [routerLinkActive]="['active']">
              <i class="fas fa-layer-group me-1"></i> Submissions
            </a>
          </li>

          <li class="nav-item"  *ngIf="currentUser_is_superuser == false">
            <a class="nav-link" routerLink="/dashboard/user-submissions" [routerLinkActive]="['active']">
              <i class="fas fa-layer-group me-1"></i> Submissions
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/profile" [routerLinkActive]="['active']">
              <i class="fas fa-id-card me-2"></i> My Profile
            </a>
          </li>
         
        </ul>

        <div class="">
          <span class="me-2">{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
          <img class="img-fluid img-profile rounded-circle me-2 cursor-pointer dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" src="{{ currentUser.profile }}" title="Sign Out" (click)="logout()" />
          <ul class="dropdown-menu" >
              <li><a class="dropdown-item" href="#" title="Sign Out" (click)="logout()"> <i class="fas fa-sign-out-alt"></i> Sign Out</a></li>
            </ul>
          <!-- <button class="btn btn-outline-primary" (click)="logout()">
              <i class="fas fa-sign-out-alt"> Sign out</i>
          </button> -->
      </div>

   
      </div>
    </div>
  </nav>