import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-cost-details',
  templateUrl: './cost-details.component.html',
  styleUrls: ['./cost-details.component.scss']
})
export class CostDetailsComponent implements OnInit {
  @Input() parentCostData:any={};
  @Input() is_approved:any

  constructor() { }

  ngOnInit(): void {
  }

}
