<div *ngIf="currentPath != '/travelform' && currentPath != '/approve-form'"
    class="d-flex flex-wrap row flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom justify-content-end">
    <div class="col-9">
        <p class="h5 mb-1 text-gray-800 fw-bold text-primary"> <i class="fas fa-route me-2"></i> {{headline}} </p>
        <p class="mb-1 small"> {{tagline}} </p>
    </div>
    <div class="btn-toolbar mb-2 mb-md-0 align-items-center justify-content-end col-3">
        <!-- <span class="me-2">{{ currentUser.first_name }} {{ currentUser.last_name }}</span> -->
        
        <!-- <div class="dropdown"> -->
            
                <!-- <img class="img-fluid img-profile rounded-circle me-2 cursor-pointer dropdown-toggle" title="Sign Out" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" src="{{ currentUser.profile }}"  /> -->

            <!-- <ul class="dropdown-menu" >
              <li><a class="dropdown-item" href="#" title="Sign Out" (click)="logout()"> <i class="fas fa-sign-out-alt"></i> Sign Out</a></li>
            </ul> -->
          <!-- </div> -->
        <!-- <button class="btn btn-outline-primary" (click)="logout()">
            <i class="fas fa-sign-out-alt"> Sign out</i>
        </button> -->
    </div>
</div>

<div class="container-fluid mt-2" *ngIf="currentPath == '/travelform'">
    <div class="d-flex justify-content-between align-items-center border-bottom">
        <div class="bfs__logo p-2">
            <a routerLink="">
                <h2>
                    <strong>Buyer<span style="color: #c1011a">Foresight</span></strong>
                </h2>
            </a>
        </div>
        <div class="" *ngIf="currentPath == '/travelform'">
            <h3>Travel Requisition Form</h3>
        </div>
        <!-- <div class="">
            <span class="me-2">{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
            <img class="img-fluid img-profile rounded-circle me-2 cursor-pointer dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" src="{{ currentUser.profile }}" title="Sign Out" (click)="logout()" />
            <ul class="dropdown-menu" >
                <li><a class="dropdown-item" href="#" title="Sign Out" (click)="logout()"> <i class="fas fa-sign-out-alt"></i> Sign Out</a></li>
              </ul>
        </div> -->
    </div>
</div>


<div class="container-fluid mt-2" *ngIf="currentPath == '/approve-form'">
    <div class="d-flex justify-content-center align-items-center border-bottom">
        <div class="bfs__logo p-2">
            <a routerLink="">
                <h2>
                    <strong>Buyer<span style="color: #c1011a">Foresight</span></strong>
                </h2>
            </a>
        </div>
    </div>
</div>