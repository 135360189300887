import { Injectable } from "@angular/core";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapCommon } from "src/app/app-providers/trf-base/MapCommon";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { LoginComponent } from "../login.component";

@Injectable({
    providedIn: "root",
})

export class LoginViewModel { 
    
    parentLogin: LoginComponent;
    parentAppcomponent: AppComponent;

    constructor(private apiService: MapAPIService) { } 

    logMeIn(requestObj: any) {
        let d: IApiServiceDelegate = {
            Data: requestObj,
            Method: "POST",
            Action: MapAPICollection.LOGIN_USER,
            Success: (e: any) => {
                MapCommon.setloggedInUser(e);
                this.parentLogin.userDetailReceived();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while login', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

  

   
}