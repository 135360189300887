import { Injectable } from "@angular/core";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapCommon } from "src/app/app-providers/trf-base/MapCommon";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { TravelRequisitionComponent } from "../travel-requisition.component";
import { TRFModel,FlightTicketModel,TrainTicketModel,AccomodationModel, ProfileResponseModel } from "../models/travel-requisition-model";
import { BehaviorSubject, Observer } from "rxjs";



@Injectable({
    providedIn: "root",
})

export class TRFViewModel { 
    
    parentLead: TravelRequisitionComponent;
    parentAppcomponent: AppComponent;
    travelRequisitionComponent:TravelRequisitionComponent
    profileResponseModel = new BehaviorSubject<ProfileResponseModel>(
        new ProfileResponseModel()
        );
    

    constructor(private apiService: MapAPIService) { } 

    subscribeProfileDetail(observerProfileesponseModel: Observer<ProfileResponseModel>) {

        this.profileResponseModel.subscribe(observerProfileesponseModel);
       

}

    getPostData(trfmodel: TRFModel,flightmodel:FlightTicketModel,trainmodel:TrainTicketModel,accomodation:AccomodationModel) {
        var jsonPostData = {
            "first_name":trfmodel.first_name,
            "middle_name": trfmodel.middle_name,
            "last_name": trfmodel.last_name,
            "employeeid": trfmodel.employeeid,
            "department": trfmodel.department,
            "designation": trfmodel.designation,
            "dob": trfmodel.dob,
            "gender":trfmodel.gender,
            "mobile":trfmodel.mobile,
            "passport_number":trfmodel.passport_number,
            "region":trfmodel.region,
            "event_location":trfmodel.event_location,
            "travel_purpose":trfmodel.travel_purpose,
            "event_name":trfmodel.event_name,
            "event_date":trfmodel.event_date,
            "user": trfmodel.userid,
            "approver":trfmodel.approver,
            "recommendation":trfmodel.recommendation,
            "flightdetail":flightmodel,
            "traindetail":trainmodel,
            "accomodationdetail":accomodation,



        }
        return jsonPostData;
    }
    

    postData(trfmodel: TRFModel,flightmodel:FlightTicketModel,trainmodel:TrainTicketModel,accomodation:AccomodationModel) {
        let d: IApiServiceDelegate = {
            Data: this.getPostData(trfmodel,flightmodel,trainmodel,accomodation),
            Method: "POST",
            Action: MapAPICollection.LEAD ,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Travel request data submitted', 'primary');
     
                this.travelRequisitionComponent.dataPosted()
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert(e, 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getProfileDetails() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.PROFILE,
            Success: (e: any) => {
        
                this.profileResponseModel.next(e);
                this.travelRequisitionComponent.DataRecieved()
                // console.log(e)
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error While profile data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getAllChoices() {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.ALL_CHOICES,
            Success: (e: any) => {
        
                // this.profileResponseModel.next(e);
                this.travelRequisitionComponent.choicesReceived(e.results)
                // console.log(e)
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error While choices data', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


   
}