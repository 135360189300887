import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IApiServiceDelegate } from './map-api-IApiServiceDelegate';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { environment } from '../../../environments/environment';

@Injectable()
export class MapAPIService {
  // base endpoint of api server
  //  url = 'https://map-api-dev.buyerforesight.net/bfsmap/v2';
  // url = 'https://api.map.buyerforesight.net/bfsmap/v2';
  url = environment.base_full_api_url;

  constructor(private http: HttpClient) { }

  // this method is provided to client classes to call api
  callService(data: IApiServiceDelegate) {
    var newURL = this.url + data.Action;
    this.execute(data.Data, newURL, data.Method).subscribe(
      (result: any) => {
        let res = result.res;
        data.Success(res);
      },
      (error: any) => {
        if (typeof error['Errors'] !== 'undefined') {
          data.Error(error['Errors']);
        }
      }
    );
  }

   

  // to create request headers
  getHeaderOptions() {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    if (MapCommon.getloggedInUser() && MapCommon.getloggedInUser().token){
      httpHeaders = httpHeaders.set("Authorization", "Token " + MapCommon.getloggedInUser().token);
    }
    const httpOptions = {
      headers: httpHeaders
    };
    return httpOptions;
  }

  execute(apiData: any, url: string, method: String) {
    if (method == 'POST') {
      return this.http
        .post(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));
    } else if (method == 'DELETE') {
      return this.http
        .delete(url, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));
    } else if (method == 'PATCH') {
      return this.http
        .patch(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));

    } else if (method == 'PUT') {
      return this.http
        .put(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));

    } else {
      return this.http
        .get(url, this.getHeaderOptions())
        .pipe(
          map((res: Response) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.errorHandler));
    }
  }

  errorHandler(e: any) {
       return throwError({ Errors: e.error });
  }

  uploadFile(url: string, formData: FormData, headers: HttpHeaders){

    if (MapCommon.getloggedInUser() && MapCommon.getloggedInUser().token){
      headers = headers.set("Authorization", "Token " + MapCommon.getloggedInUser().token);
    }
  
  
    var newURL = this.url + url;
    const httpOptions = {
      headers: headers,
    };
    // var httpOptions = this.getHeaderOptions();
  
    return this.http.post(newURL, formData, httpOptions).pipe(
      map((res: Response) => {
        return { res: res };
      })
    )
    .pipe(catchError(this.errorHandler));
  }
}
