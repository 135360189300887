<div class="row justify-content-center align-items-center vh-100" style="background-color: #020202;">
  <div class="col-auto">
    <div class="login">
      <!-- Header -->
      <div class="login__header">
        <img class="login__img" src="https://apps.buyerforesight.net/logo/BF-NEW-LOGO-RGB-500-TM.png"
          alt="buyerforesight logo">
      </div>
      <!-- Content -->
      <div class="login__content">
        <h2><strong>Buyer<span style="color: #c1011a;">Foresight</span></strong> Traveldesk</h2>
        <p>Sign in using Google</p>
      </div>
      <!-- Footer -->
      <div class="login__footer">
        <a href="javascript:void(0)" (click)="connectWithGoogle()">
          <img src="https://apps.buyerforesight.net/logo/google-login.png" alt="">
        </a>
      </div>
    </div>
  </div>
</div>