<div class="bg-white shadow-sm top-header">

    <div class="chat-header p-3">
        <div class="d-flex justify-content-between w-100">
            <h6 class="fw-bold mb-0"> <i class="fas fa-comments"></i> Conversations </h6>
            <h6 class="mb-0">Messages :&nbsp; {{totalComments}} </h6>
        </div>
    </div>

    <div class="d-flex justify-content-between p-3">

        <div class="w-100">
            <quill-editor [(ngModel)]="masterComment" theme="snow" placeholder="Write comment" class="content-editor"
                [modules]="quillConfig"></quill-editor>

        </div>

        <div class="ms-2">
            <div>
                <button type="button" class="btn btn-primary editor-btn" (click)="handleComment()"
                    [disabled]="masterComment?.length == 0">
                    <i class="fas fa-paper-plane" *ngIf="!editCommentModel"></i>
                    <i class="far fa-edit" *ngIf="editCommentModel"></i>
                </button>
            </div>

            <div class="mt-1">
                <input mdbInput type="file" [(ngModel)]="fileinput" style="display: none;" #attachment id="attachment"
                    class="form-control" (change)="onFileChange($event)" multiple />

                <button type="button" class="btn btn-outline-secondary editor-btn" (click)="attachment.click()">
                    <i class="fas fa-paperclip"></i>
                </button>
            </div>



        </div>

    </div>



    <div class="selected-attachments" *ngIf="attachments?.length > 0">
        <ul class="list-group p-0 bg-white">
            <li class="list-group-item p-1 rounded-0 ps-3 pe-3" *ngFor="let item of attachments">
                <div class="d-flex justify-content-between">
                    <p class="mb-0">  <i class="fas fa-paperclip"></i> {{item.name}}</p>
                    <p class="mb-0"><a href="javascript:void(0)" (click)="removeFile(item)" class="text-danger">
                            <i class="fas fa-times"></i>
                        </a></p>
                </div>
            </li>
        </ul>
    </div>


</div>


<div class="chat-wrapper mt-2">

    <div class="chat-container" *ngIf="commentsData.length > 0; else noComments;">
        <ul class="list-group list-group-flush chat-list-container">
            <li class="list-group-item" *ngFor="let comment of commentsData;">
                <div class="d-flex">
                    <img src="../../../../../assets/images/avatar.svg" alt="avtar" height="25px" width="25px">
                    <div class="ms-2 chat-item chat-item-left">
                        <h1 class="chat-item-name mb-0">{{comment.created_by_name}}</h1>
                        <div class="chat-item-message mb-0" [innerHTML]="comment.comment|noSanitizeHtml"></div>


                        <div *ngFor="let file of comment.attachment_detail" [title]=" extractFileName(file.name)">
                            <div class="d-flex mb-1" style="font-size: 10px;">
                                <a href="{{ base_api_url }}{{ file.url }}" target="_blank" class="importStatusItem"
                                    download="{{file.name}}">
                                    <i class="fas fa-download ms-2 me-1"></i> {{ extractFileName(file.name) | titlecase
                                    | slice:0:52}}
                                </a>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="d-flex justify-content-start">

                    <p class="mb-0 chat-item-time">
                        {{comment.created_at | date:'medium'}}
                    </p>


                    <div class="d-flex mb-1 me-2 mt-1" *ngIf="comment.created_by == loggedInUser?.id">
                        <div class="editdelete">
                            <a class="spanButton" href="javascript:void(0)" (click)="editComment(comment)">
                                <i class="fas fa-edit cicon"></i> Edit
                            </a>
                        </div>
                        <div class="editdelete">
                            <a class="spanButton" href="javascript:void(0)" (click)="deleteComment(comment.id)">
                                <i class="fas fa-trash-alt cicon"></i>
                                Delete </a>
                        </div>
                    </div>
                </div>



            </li>
        </ul>
    </div>

    <ng-template #noComments>
        <div class="chat-container pt-5">

            <p class="text-center mt-4">
                <img src="../../../../assets/images/chat-no-message.svg" alt="" height="auto" width="300px">
            </p>
            <h4 class="text-center text-muted mt-5 mb-3">
                <i class="fas fa-comment-slash"></i> Please add a new comment.
            </h4>

        </div>
    </ng-template>
</div>




<!-- <footer class="footer shadow-sm p-3 bg-white  mt-auto">
 
</footer> -->