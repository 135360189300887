<app-topbar></app-topbar>
<section class="checkout standard-text mt-4 pv-100 w3_bg">
  <div class="container w3_bg">
    <div class="row justify-content-center checkout__forms mt-3 r-gap-40 w3_bg">
      <div class="col-lg-10 w3_bg">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                <i class="fas fa-address-card">
                  Mandatory Information of The Traveler</i>
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="name" id="first_name" [(ngModel)]="trfmodel.first_name" name="first_name"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="first_name">First Name</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="middle_name" [(ngModel)]="trfmodel.middle_name" name="middle_name"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="middle_name">Middle Name</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="last_name" [(ngModel)]="trfmodel.last_name" name="last_name"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="last_name">Last Name</label>
                    </mdb-form-control>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="employee_id" [(ngModel)]="trfmodel.employeeid" name="employee_id"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="employee_id">Employee ID</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="department" [(ngModel)]="trfmodel.department" name="department"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="department">Department
                      </label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="designation" [(ngModel)]="trfmodel.designation" name="designation"
                        class="form-control" />
                      <label mdbLabel class="form-label" for="designation">Designation</label>
                    </mdb-form-control>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="dob" [(ngModel)]="trfmodel.dob" name="dob" class="form-control" />
                      <label mdbLabel class="form-label" for="dob">D.O.B</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-2"> -->
                    <select class="form-select" aria-label="Default select example" [(ngModel)]="trfmodel.gender">
                      <option selected>Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.mobile" id="mobile" type="text"
                        name="mobile" />
                      <label mdbLabel class="form-label" for="mobile">Mobile</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.passport_number" id="passport-number"
                        name="passport-number" />
                      <label mdbLabel class="form-label" for="passport-number">Passport number</label>
                    </mdb-form-control>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example" [(ngModel)]="trfmodel.region">
                      <option selected>Region</option>
                      <option value="NA">NA</option>
                      <option value="EU">EU</option>
                      <option value="ME">ME</option>
                      <option value="APAC">APAC</option>
                      <option value="ANZ">ANZ</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_location" id="event-loc"
                        name="eventlocation" />
                      <label mdbLabel class="form-label" for="event-loc">Event Location</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example"
                      [(ngModel)]="trfmodel.travel_purpose">
                      <option selected>Purpose of travel</option>
                      <option value="sales">Sales</option>
                      <option value="internal">Internal</option>
                      <option value="event">Event</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_name" id="eventname"
                        name="eventname" />
                      <label mdbLabel class="form-label" for="eventname">Customer/Event name</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.approver" id="approver" name="approver"
                        type="email" />
                      <label mdbLabel class="form-label" for="approver">Approver</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput class="form-control" [(ngModel)]="trfmodel.event_date" id="eventdate"
                        name="eventdate" type="date" />
                      <label mdbLabel class="form-label" for="eventdate">Event Date</label>
                    </mdb-form-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #flight_accordion class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <i class="fas fa-plane"> For Flight Tickets</i>
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="date_of_travel" class="form-control"
                        [(ngModel)]="flightmodel.traveldate" />
                      <label mdbLabel class="form-label" for="date_of_travel">Date of Travel</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="destination" class="form-control"
                        [(ngModel)]="flightmodel.destination" />
                      <label mdbLabel class="form-label" for="destination">Destination Cities / Airport
                        (From-To)</label>
                    </mdb-form-control>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="time_slot" class="form-control"
                        [(ngModel)]="flightmodel.timeslot" />
                      <label mdbLabel class="form-label" for="time_slot">Time Slot</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="multi-city_travel" class="form-control"
                        [(ngModel)]="flightmodel.multiple_destination" />
                      <label mdbLabel class="form-label" for="multi-city_travel">Multi-City Travel Date &
                        Destination</label>
                    </mdb-form-control>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="return_date_of_travel" class="form-control"
                        [(ngModel)]="flightmodel.returndate" />
                      <label mdbLabel class="form-label" for="return_date_of_travel">Return Date of Travel</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="return_destination" class="form-control"
                        [(ngModel)]="flightmodel.return_destination" />
                      <label mdbLabel class="form-label" for="return_destination">Destination Cities / Airport
                        (From-To)</label>
                    </mdb-form-control>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example"
                      [(ngModel)]="flightmodel.travelclass">
                      <option selected>Travel Class</option>
                      <option value="Business">Business</option>
                      <option value="Economy">Economy</option>
                      <option value="Premium Economy">Premium Economy</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="return_time_slot" class="form-control"
                        [(ngModel)]="flightmodel.return_timeslot" />
                      <label mdbLabel class="form-label" for="return_time_slot">Time Slot</label>
                    </mdb-form-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #train_accordion class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <i class="fas fa-subway"> For Train Tickets</i>
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="date" id="tr_date_of_journey" class="form-control"
                        [(ngModel)]="trainmodel.startdate" />
                      <label mdbLabel class="form-label" for="tr_date_of_journey">Date of Journey</label>
                    </mdb-form-control>
                  </div>

                  <div class="col-md-4">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="tr_train_name_or_number" class="form-control"
                        [(ngModel)]="trainmodel.train" />
                      <label mdbLabel class="form-label" for="tr_train_name_or_number">Train Name / Number</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-4">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example"
                      [(ngModel)]="trainmodel.ticketclass">
                      <option selected>Class</option>
                      <option value="1A">1A</option>
                      <option value="2A">2A</option>
                      <option value="3A">3A</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="tr_from_station_name" class="form-control"
                        [(ngModel)]="trainmodel.fromstation" />
                      <label mdbLabel class="form-label" for="tr_from_station_name">From (Station Name)</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="text" id="tr_to_station_name" class="form-control"
                        [(ngModel)]="trainmodel.tostation" />
                      <label mdbLabel class="form-label" for="tr_to_station_name">To (Station Name)</label>
                    </mdb-form-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #accommodation class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <i class="fas fa-hotel"> For Accommodation</i>
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example" [(ngModel)]="accomodation.stay">
                      <option selected>Stay</option>
                      <option value="hotel">Hotel</option>
                      <option value="airbnb">Airbnb</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                  <div class="col-md-6">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example"
                      [(ngModel)]="accomodation.occupancy">
                      <option selected>Occupancy</option>
                      <option value="Single">Single</option>
                      <option value="Double">Double</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mt-2">
                      <input mdbInput type="date" id="htl_date_of_check_in" class="form-control"
                        [(ngModel)]="accomodation.checkin" />
                      <label mdbLabel class="form-label" for="htl_date_of_check_in">Date of Check In</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mt-2">
                      <input mdbInput type="date" id="htl_date_of_check_out" class="form-control"
                        [(ngModel)]="accomodation.checkout" />
                      <label mdbLabel class="form-label" for="htl_date_of_check_out">Date of Check Out</label>
                    </mdb-form-control>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <i class="fa fa-dollar-sign"> Cost Detail </i>
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="number" id="tr_date_of_journey" class="form-control" name="totalcost"
                        [(ngModel)]="travelcost.totalcost" />
                      <label mdbLabel class="form-label" for="tr_date_of_journey">Total Cost</label>
                    </mdb-form-control>
                  </div>

                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="number" id="tr_train_name_or_number" class="form-control" name="tavelcost"
                        [(ngModel)]="travelcost.travelcost" />
                      <label mdbLabel class="form-label" for="tr_train_name_or_number">Travel Cost</label>
                    </mdb-form-control>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="number" id="tr_from_station_name" class="form-control"
                        [(ngModel)]="travelcost.accomodationcost" name="accommodationcost" />
                      <label mdbLabel class="form-label" for="tr_from_station_name">Accommodation Cost</label>
                    </mdb-form-control>
                  </div>

                  <div class="col-md-6">
                    <!-- <mdb-form-control class="mb-4"> -->
                    <select class="form-select" aria-label="Default select example" name="currency"
                      [(ngModel)]="travelcost.currency">
                      <option selected value="INR">INR</option>
                      <option value="USD">USD</option>
                    </select>
                    <!-- </mdb-form-control> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item mb-2 shadow-sm">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                <i class="fa fa-sticky-note"> Remarks </i>
              </button>
            </h2>
            <div id="collapsesix" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <textarea mdbInput type="number" id="tr_date_of_journey" rows="5" class="form-control" name="remarks"
                        [(ngModel)]="remarks.remark"></textarea>
                      <label mdbLabel class="form-label" for="tr_date_of_journey">Remarks</label>
                    </mdb-form-control>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control class="mb-2">
                      <input mdbInput type="file" id="tr_from_station_name" class="form-control"
                        (change)="onFileChange($event)" multiple />
                    </mdb-form-control>

                    <div class="row mt-3" *ngIf="remarks.filedetail != []">
                      <div class="col">
                        <h6 class="text-muted mb-3">List of Documents</h6>
                        <ul class="list-group">
                          <li *ngFor="let file of remarks.filedetail"
                            class="list-group-item d-flex justify-content-between align-items-center">
                            {{ file.name | titlecase }}
                            <div class="image-parent" *ngIf="file.type == 'image'">
                              <a href="{{ base_api_url }}{{ file.url }}" target="_blank" rel="noopener noreferrer">
                                <img src="{{ base_api_url }}{{ file.url }}" class="img-fluid" alt="{{ file.name | titlecase }}" />
                              </a>
                            </div>
                            <div class="image-parent pdf_file" *ngIf="file.type == 'document'">
                              <a href="{{ base_api_url }}{{ file.url }}" target="_blank" rel="noopener noreferrer">
                                <img src="../../../assets/images/pdf-svgrepo-com.svg" alt="pdf file">
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="remarks.filedetail !=[]">
                  <div *ngFor="let file of remarks.filedetail">

                    <img src="{{base_api_url}}{{file.url}}" alt="{{file.name}}">
                  </div>

                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row r-gap-40 my-5">
      <div class="col d-flex justify-content-center">
        <button id="form_submit" type="button" class="btn btn-primary" (click)="markdone()">
          Mark FullFilled
        </button>
      </div>
    </div>
  </div>
</section>