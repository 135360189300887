import { Component, OnInit } from '@angular/core';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  currentUser_is_superuser: boolean = false;
  currentRoute: string = 'dashboard';

  constructor() {
    const localStorage_user = MapCommon.getloggedInUser();
    this.currentUser_is_superuser = localStorage_user.is_superuser;
    // console.log(this.currentUser_is_superuser);
  }

  ngOnInit(): void {}

  show_only_for_user() {
    if (this.currentUser_is_superuser) {
      return false;
    }
    return true;
  }
  show_only_for_admin() {
    if (this.currentUser_is_superuser) {
      return true;
    }
    return false;
  }

  isActive(route: string): boolean {
    console.log('route', route);
   
    return this.currentRoute === route;
  }

  navigateTo(route: string): void {
     console.log('currentRoute', this.currentRoute);
    this.currentRoute = route;
  }
}
