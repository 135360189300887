export class MapAPICollection {

static LOGIN_USER: String = '/login';
static LEAD: String = '/trform';
static ACTION: String = '/trform/action_on_trf';
static TRFDETAIL: String = '/trform/get_form_detail';
static MARKDONE: String = '/trform/mark_full_filled';
static UPLOADFILE: String = '/trform/upload_file';
static GETCOUNTS: String = '/trform/get_counts';
static PROFILE: String = '/profile';
static TRF_COMMENT: String = '/trf-comment'
static COMMENT_ATTACH: String = '/comment-attachment'
static UPDATE_COMMENT: String ='/trf-comment/update_comment'
static ALL_CHOICES: String = '/all-choices'



}

