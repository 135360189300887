import { Injectable } from "@angular/core";
import { AlertNotificationsComponent } from "src/app/Alerts/alert-notifications/alert-notifications.component";
import { MapCommon } from "src/app/app-providers/trf-base/MapCommon";
import { MapAPIService } from "src/app/app-providers/services/map-api-service"; 
import { AppComponent } from "src/app/app.component";
import { MapAPICollection } from "src/app/app-providers/services/map-api-collection";
import { IApiServiceDelegate } from "src/app/app-providers/services/map-api-IApiServiceDelegate";
import { BehaviorSubject,Observer } from 'rxjs';
import { TRFModel,FlightTicketModel,TrainTicketModel,AccomodationModel, TRFComment, TRFCommentResponseModel, TRFResponseModel } from "src/app/travel-requisition/models/travel-requisition-model";
import { FormDetailComponent } from "../form-detail.component";
import { TravelCost,RemarksModel } from "src/app/travel-requisition/models/travel-requisition-model";
import { HttpHeaders } from "@angular/common/http";
import { SubmissionComponent } from "../../main-content/submission/submission.component";
import { ConversionComponent } from "../../main-content/conversion/conversion.component";


@Injectable({
    providedIn: "root",
})

export class FormDetailViewModel { 
    
    parentSubmission: SubmissionComponent;
    parentAppcomponent: AppComponent;
    parentComponentDetails: FormDetailComponent;
    conversionComponent:ConversionComponent;
    trfModel = new BehaviorSubject<TRFModel>(
        new TRFModel()
      );
    
    flightModel = new BehaviorSubject<FlightTicketModel>(
    new FlightTicketModel()
    );

    trainModel = new BehaviorSubject<TrainTicketModel>(
        new TrainTicketModel()
        );

    accomodationModel = new BehaviorSubject<AccomodationModel>(
        new AccomodationModel()
        );
    
    travelcostModel = new BehaviorSubject<TravelCost>(
        new TravelCost()
        );
    
    remarksModel = new BehaviorSubject<RemarksModel>(
        new RemarksModel()
        );
    commentsResponseModel = new BehaviorSubject<TRFCommentResponseModel>(new TRFCommentResponseModel);
    
    subscribeDetailVariables(observerTRFModel: Observer<TRFModel>, observerFlightModel: Observer<FlightTicketModel>, observerTrainModel: Observer<TrainTicketModel>, observerAccomodationModel: Observer<AccomodationModel>,observertravelcostModel: Observer<TravelCost>,observerremarkModel:Observer<RemarksModel> ) {
    this.trfModel.subscribe(observerTRFModel);
    this.flightModel.subscribe(observerFlightModel);
    this.trainModel.subscribe(observerTrainModel);
    this.accomodationModel.subscribe(observerAccomodationModel);
    this.travelcostModel.subscribe(observertravelcostModel);
    this.remarksModel.subscribe(observerremarkModel);

}
    subscribeComments(observerTRFResponseModel:Observer<TRFCommentResponseModel>){
        this.commentsResponseModel.subscribe(observerTRFResponseModel);

    }

    constructor(private apiService: MapAPIService) { } 


    getPostData(trfmodel: TRFModel,flightmodel:FlightTicketModel,trainmodel:TrainTicketModel,accomodation:AccomodationModel,travelcost:TravelCost) {
        var jsonPostData = {
            "first_name":trfmodel.first_name,
            "middle_name": trfmodel.middle_name,
            "last_name": trfmodel.last_name,
            "employeeid": trfmodel.employeeid,
            "department": trfmodel.department,
            "designation": trfmodel.designation,
            "dob": trfmodel.dob,
            "gender":trfmodel.gender,
            "mobile":trfmodel.mobile,
            "passport_number":trfmodel.passport_number,
            "region":trfmodel.region,
            "event_location":trfmodel.event_location,
            "travel_purpose":trfmodel.travel_purpose,
            "event_name":trfmodel.event_name,
            "event_date":trfmodel.event_date,
            "user": trfmodel.userid,
            "approver":trfmodel.approver,
            "recommendation":trfmodel.recommendation,
            "is_fullfilled":trfmodel.is_fullfilled,
            "flightdetail":flightmodel,
            "traindetail":trainmodel,
            "accomodationdetail":accomodation,
            "travelcostdetail":travelcost



        }
        return jsonPostData;
    }

    performAction(id:string,trfmodel: TRFModel,flightmodel:FlightTicketModel,trainmodel:TrainTicketModel,accomodation:AccomodationModel,travelcost:TravelCost) {
        let d: IApiServiceDelegate = {
            Data: this.getPostData(trfmodel,flightmodel,trainmodel,accomodation,travelcost),
            Method: "PATCH",
            Action:MapAPICollection.LEAD + '/' + id,
            Success: (e: any) => {
                AlertNotificationsComponent.alertCall.showAlert('Request marked  fulfiled', 'primary');
                this.parentSubmission.markedFullfilled()
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error'+e, 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    uploadfile(id:string,remarks:RemarksModel){
        var formData = new FormData();
        const files:FileList = remarks.files
        // console.log(files)
        var httpHeaders = new HttpHeaders();
        var uploadContentType = { type: 'multipart/form-data' }
        formData.set('remark', remarks.remark);
        formData.set('id', id);

        for (let i = 0; i < files.length; i++) {
            // console.log(files[i])
            var file = files[i]
            const blob = new Blob([files[i]], uploadContentType);
            formData.append('files', blob, file.name);
          }
        httpHeaders.append('Accept', 'application/x-www-form-urlencoded');
        this.apiService.uploadFile(String(MapAPICollection.UPLOADFILE), formData,  httpHeaders).subscribe((e: any) => {

            AlertNotificationsComponent.alertCall.showAlert('Attachment added successfully', 'primary');
        },
        (error: any) => {
            if (error.Errors.media_file[0] ) {
                AlertNotificationsComponent.alertCall.showAlert(' File size too big', 'danger');
            }
            else {
            AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
            }
        }
        );

    }


    createTRFComment(comment:TRFComment){
        var formData = new FormData();
        const files:FileList = comment.attachment
        // console.log(files)
        var httpHeaders = new HttpHeaders();
        var uploadContentType = { type: 'multipart/form-data' }
        formData.set('comment', comment.comment);
        formData.set('trmodel',comment.trmodel.toString());
        formData.set('created_by',comment.created_by.toString());
        if (files){

            for (let i = 0; i < files.length; i++) {
                var file = files[i]
                const blob = new Blob([files[i]], uploadContentType);
                formData.append('attachment', blob, file.name);
              }
        }
        httpHeaders.append('Accept', 'application/x-www-form-urlencoded');
        this.apiService.uploadFile(String(MapAPICollection.TRF_COMMENT), formData,  httpHeaders).subscribe((e: any) => {

            // AlertNotificationsComponent.alertCall.showAlert('Attachment added successfully', 'primary');
            this.conversionComponent.commentUpdated();

        },
        (error: any) => {
            if (error.Errors.media_file[0] ) {
                AlertNotificationsComponent.alertCall.showAlert(' File size too big', 'danger');
            }
            else {
            AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
            }
        }
        );

    }


    updateTRFComment(comment:TRFComment){
        var formData = new FormData();
        const files:FileList = comment.attachment
        // console.log(files)
        var httpHeaders = new HttpHeaders();
        var uploadContentType = { type: 'multipart/form-data' }
        formData.set('comment', comment.comment);
        formData.set('id', comment.id.toString());
        formData.set('modified_by',comment.modified_by.toString());
        if (files){

            for (let i = 0; i < files.length; i++) {
                var file = files[i]
                const blob = new Blob([files[i]], uploadContentType);
                formData.append('attachment', blob, file.name);
              }
        }
        httpHeaders.append('Accept', 'application/x-www-form-urlencoded');
        this.apiService.uploadFile(String(MapAPICollection.UPDATE_COMMENT), formData,  httpHeaders).subscribe((e: any) => {

            // AlertNotificationsComponent.alertCall.showAlert('Attachment added successfully', 'primary');
            this.conversionComponent.commentUpdated();

        },
        (error: any) => {
            if (error.Errors.media_file[0] ) {
                AlertNotificationsComponent.alertCall.showAlert(' File size too big', 'danger');
            }
            else {
            AlertNotificationsComponent.alertCall.showAlert('Something went wrong', 'danger');
            }
        }
        );

    }

    getFormDetail(formid: string) {
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.LEAD + '/' + formid,
            Success: (e: any) => {
                this.trfModel.next(e);
                this.flightModel.next(e.flightdetail);
                this.trainModel.next(e.traindetail);
                this.accomodationModel.next(e.accomodationdetail);
                this.travelcostModel.next(e.travelcostdetail);
                this.remarksModel.next(e.remarkdetail);
                this.parentSubmission.detailRecive();
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }


    getTRFComments(Id){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.TRF_COMMENT + '?page=1&page_size=1000'+ '&trmodel=' + String(Id),
            Success: (e: any) => {
                this.commentsResponseModel.next(e);
                this.conversionComponent.commentsReceived()
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while loading comments', 'danger');

                return e;
            },
        };
        this.apiService.callService(d);
    }

    deleteComment(commentId, formId){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.TRF_COMMENT + '/' + String(commentId),
            Success: (e: any) => {
                this.getTRFComments(formId);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting comments', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

    getComment(id){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: MapAPICollection.TRF_COMMENT + '/' + String(id),
            Success: (e: any) => {
                // this.getTRFComments(formId);
                this.conversionComponent.editComment(e);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting comments', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);

    }
    
    deleteCommentAttachment(Id,comment: TRFComment,edit:boolean=false){
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "DELETE",
            Action: MapAPICollection.COMMENT_ATTACH + '/' + String(Id),
            Success: (e: any) => {
                if (edit){
                    this.getComment(comment.id);
                }
                this.getTRFComments(comment.trmodel);
                return e;
            },
            Error: (e: String) => {
                AlertNotificationsComponent.alertCall.showAlert('Error while deleting comments', 'danger');
                return e;
            },
        };
        this.apiService.callService(d);
    }

  

   
}