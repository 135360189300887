
export class TRFModel {
    "id": number
    "first_name": number
    "middle_name": string
    "last_name": string
    "employeeid": string
    "department": string
    "designation": string
    "dob": string
    "gender":string
    'mail':string
    "mobile":string
    "passport_number":string
    "region":string
    "event_location":string
    "travel_purpose":string
    "event_name":string
    "event_date":Date
    "userid": string
    "approver":string
    "recommendation":string
    "is_fullfilled":boolean
    "is_disapproved":boolean
    "is_approved":boolean
    "traveller_email":string
}



export class FlightTicketModel{
    "traveldate":Date
    "destination":string
    "timeslot":string
    "multiple_destination":string
    "returndate":Date
    "return_destination":string
    "travelclass":string
    "return_timeslot":string
    "preferedtime":string
    "seat_preference":number
    "seat_preference_display":string

}

export class TrainTicketModel{
    "startdate":Date
    "fromstation":string
    "tostation":string
    "train":string
    "ticketclass":string

}

export class AccomodationModel{
    "location":string
    "checkin":Date
    "checkout":Date
    "occupancy":string
    "preferedlocation":string
    "dietary_restriction":string
    "meal_preference":number
    "meal_preference_display":string

}

export class Profile {
    "employeeid": number
    "department": string
    "designation": string
    "dob":string
    "gender": string
    "mobile": number
    "passport_number":number
    "user": number
    "manager":string
}


export class TRFResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": TRFModel[]
}
export class ProfileResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": Profile[]
}

export class TravelCost{
    "totalcost":number
    "travelcost":number
    "accomodationcost":number
    "currency":string
}

export class RemarksModel{
    "remark":string
    "files":FileList
    "filedetail":[]
}


export class CountModel{
    "total":number
    "rejected":number
    "approved":number
    "pending":number
}

export class TRFComment {
    'id': number
    'comment': string
    'trmodel': number
    'created_by': number
    'created_at': string
    'modified_by': number
    'created_by_name':string
    'attachment':FileList
    'attachment_detail':[]

}

export class TRFCommentResponseModel {
    "count": number
    "next": string
    "previous": string
    "results": [TRFComment]
}
export class CommentAttachment{
    'comment':number
}

export class choiceModel {
    id: number
    name: string
  }