// const APP_URL = 'https://map.buyerforesight.net';
// const API_URL = 'https://api.map.buyerforesight.net'; 
const APP_URL = 'https://traveldesk.buyerforesight.net';
const API_URL = 'https://api.traveldesk.buyerforesight.net';
 
export const environment = {
  version: '2.10.1',
  production: false,
  base_full_api_url: API_URL+'/api',
  base_api_url: API_URL,
  base_app_url:APP_URL
};