<!-- <div class="container-fluid">
  <div class="row"> 
      <app-sidebar id="sidebar" class="col-md-2 col-lg-2 d-md-block bg-light sidebar shadow"></app-sidebar>
 
     <app-main-content class="col-md-10 ms-sm-auto col-lg-10 px-md-4"></app-main-content>
  </div>
</div> -->

<main class="main">
  <app-navbar></app-navbar>

  <div [ngClass]="isSubmissionPage ? 'content-wrapper-submission-page container-fluid':'content-wrapper container'">
      <app-main-content></app-main-content>
  </div>
</main>

