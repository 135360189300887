import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDetailViewModel } from './models/form-detail-view-model';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { TRFModel,FlightTicketModel,TrainTicketModel,AccomodationModel} from 'src/app/travel-requisition/models/travel-requisition-model';
import { BehaviorSubject } from 'rxjs';
import { TravelCost ,RemarksModel} from 'src/app/travel-requisition/models/travel-requisition-model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-form-detail',
  templateUrl: './form-detail.component.html',
  styleUrls: ['./form-detail.component.scss']
})
export class FormDetailComponent implements OnInit {
  formId : string;
  base_api_url:any = environment.base_api_url;


  trfmodel: any = {};
  flightmodel: any = {};
  trainmodel: any = {};
  accomodation: any = {};
  remarks = new RemarksModel()
  travelcost =new TravelCost();
  trfModel = new BehaviorSubject<TRFModel>(
    new TRFModel()
  );
  flightModel = new BehaviorSubject<FlightTicketModel>(
    new FlightTicketModel()
  );

  trainModel = new BehaviorSubject<TrainTicketModel>(
    new TrainTicketModel()
  );

  accomodationModel = new BehaviorSubject<AccomodationModel>(
    new AccomodationModel()
  );
  
  travelcostModel = new BehaviorSubject<TravelCost>(
    new TravelCost()
  );

  remarksModel = new BehaviorSubject<RemarksModel>(
    new RemarksModel()
  );

  constructor( private vm: FormDetailViewModel,private router: Router) {
    var url = window.location.href;
    this.formId = url.substring(url.lastIndexOf('/') + 1);
    const localStorage_user = MapCommon.getloggedInUser();
    
    if(localStorage_user.is_superuser==true){
      this.vm.getFormDetail(this.formId);
    }else{
      this.router.navigate(['/travelform']);
    }

   }
  
  onFileChange(event) {
    this.remarks.files = event.target.files;
  }

  detailRecive(){
    this.trfmodel = this.trfModel.value;
    this.flightmodel = this.flightModel.value;
    this.trainmodel = this.trainModel.value;
    this.accomodation = this.accomodationModel.value;
    if (this.travelcostModel.value){

      this.travelcost = this.travelcostModel.value;
    }
    if (this.remarksModel.value){
      this.remarks = this.remarksModel.value
    }
  }

  ngOnInit(): void {
    // this.vm.parentFormDetail = this
    this.vm.subscribeDetailVariables(this.trfModel,this.flightModel,this.trainModel,this.accomodationModel,this.travelcostModel,this.remarksModel)

  }

  markdone(){
    this.trfmodel.is_fullfilled = true;
    this.vm.performAction(this.formId,this.trfmodel, this.flightmodel, this.trainmodel, this.accomodation,this.travelcost);
    this.vm.uploadfile(this.formId,this.remarks)
  }

}
