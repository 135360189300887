<!-- <div class="card h-100 shadow-sm"> -->
    <div class="card-body" >
        <div class="d-flex align-items-center justify-content-between pb-2 ">
            <h6>

                <i class="fas fa-file me-2 section-icon"></i> <span class="section-heading fw-bold">Documents</span> 
            </h6>
          

            <div class="col-6 d-flex justify-content-end">
                <div>
                    <a class="btn btn-sm collapse-btn shadow-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseDocs" aria-expanded="false" aria-controls="collapseDocs">
                    <i class="fas fa-angle-down"> </i> 
                </a>
                </div>
             </div>
            
        </div>
        <div  id="collapseDocs" class="collapse mt-2">

            <div class="row">
                <div class="col-12">
                    <hr class="mt-0">
                </div>
            </div>

            <div class="row" *ngIf="currentUser_is_superuser">
                <div class="col-12">
                    <mdb-form-control class="mb-2">
                        <input mdbInput type="file" id="" [disabled]="!is_approved" class="form-control form-control-sm" multiple (change)="fileUpload($event)" />
                    </mdb-form-control>
                </div>
            </div>
            <div class="row mt-3 ">
                <div class="col" *ngIf=" parentDocument.filedetail && parentDocument.filedetail.length>0 else nofiles">
                    <h6 class="text-muted mb-3">List of Documents</h6>
                    <ul class="list-group">
                        <li *ngFor="let file of parentDocument.filedetail" [title]=" extractFileName(file.name)"
                            class="list-group-item d-flex justify-content-between align-items-center text-truncate">
                            {{ extractFileName(file.name) | titlecase | slice:0:52}}
                            <div class="image-parent" *ngIf="file.type == 'image'">
                                <a href="{{ base_api_url }}{{ file.url }}" target="_blank" rel="noopener noreferrer">
                                    <img src="{{ base_api_url }}{{ file.url }}" class="img-fluid"
                                        alt="{{ file.name | titlecase }}" height="40px" width="40px" />
                                </a>
                            </div>
                            <div class="image-parent pdf_file" *ngIf="file.type == 'document'">
                                <a href="{{ base_api_url }}{{ file.url }}" target="_blank" rel="noopener noreferrer">
                                    <img src="{{ base_api_url }}{{ file.url }}" alt="pdf file" />
                                </a>
                            </div>
                            <span>
                                <a href="{{ base_api_url }}{{ file.url }}" target="_blank" class="importStatusItem" download="{{file.name}}">
                                    <i class="fas fa-download "></i>
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
    
                <ng-template #nofiles>
                    <h6 class="text-muted mb-3">No Document Available</h6>
    
    
                </ng-template>
            </div>
        </div>

    </div>
<!-- </div> -->