<!-- Top bar -->
<!-- <app-topbar [headline]="headline" [tagline]="tagline"></app-topbar> -->

<!-- Page Heading -->
<!-- <h1 class="h3 mb-2 text-gray-800"></h1>

<p class="mb-4"></p> -->

<!-- DataTales Example -->
<div class="row">
    <div class="col mt-3">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary mt-2">Submissions</h6>
                <div style="width: 200px"> 
                    <select class="form-select form-select-sm" (change)="getData($event)"  >
                        <option value="true">Approved</option>
                        <option value="false">Disapproved</option>
                        <option  selected value="all">All</option>
                    </select>
                </div>
            </div>
            <div class="card-body p-0 m-0" style="overflow: auto; height:70vh">
                <div class="table-responsive">
                    <table class="table table-bordered position-relative" id="dataTable" width="100%" cellspacing="0" style="white-space: nowrap;">
                        <thead class="thead-dark">
                            <tr>
                                <th>Name</th>
                                <th>Approver</th>
                                <th>Is Full Filled</th>
                                <th>Is Approved</th>
                               
                                <th>Travel Cost</th>
                                <th>Accommodation Cost</th>
                                <th>Total Cost</th>
                            </tr>
                        </thead>
                        <!-- <tfoot>
                            <tr>
                                <th>Name</th>
                                <th>Approver</th>
                                <th>Is Full Filled</th>
                                <th>Total Cost</th>
                                <th>Travel Cost</th>
                                <th>Accomodation Cost</th>
                            </tr>
                        </tfoot> -->
                        <tbody *ngIf="all_forms">
                            <tr *ngFor="let form of all_forms">
                                <td>
                                    <a href="dashboard/submission/{{ form.id }}">{{ form.first_name }}</a>
                                </td>
        
                                <td>{{ form.approver }}</td>
                                <td>  <i *ngIf="form.is_fullfilled   == true" class="fas fa-check-circle text-success"></i> <i  *ngIf="!form.is_fullfilled   == true" class="fas fa-times-circle text-danger"></i></td>
                                <td>  <i *ngIf="form.is_approved  == true" class="fas fa-check-circle text-success"></i> <i  *ngIf="!form.is_approved  == true" class="fas fa-times-circle text-secondary"></i> <i  *ngIf="form.is_disapproved  == true" class="fas fa-times-circle text-danger"></i></td>
        
                                
                                <td>
                                    {{ form.travelcostdetail ? form.travelcostdetail.travelcost : 0 }}
                                </td>
        
                                <td>
                                    {{
                                    form.travelcostdetail
                                    ? form.travelcostdetail.accomodationcost
                                    : 0
                                    }}
                                </td>
                                <td>
                                    {{ form.travelcostdetail ? form.travelcostdetail.totalcost : 0 }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="all_forms && all_forms.length == 0">
                            <tr>
                                <td colspan="6" style="text-align: center; font-weight: bold;"> No Data Found. </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner *ngIf="isLoading"></app-spinner> 