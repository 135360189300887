import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDetailViewModel } from '../../form-detail/models/form-detail-view-model';
import { environment } from 'src/environments/environment';
import { AccomodationModel, FlightTicketModel, RemarksModel, TRFComment, TRFCommentResponseModel, TRFModel, TrainTicketModel, TravelCost } from 'src/app/travel-requisition/models/travel-requisition-model';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { UIChangerService } from 'src/app/app-providers/services/ui-changer.service';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})
export class SubmissionComponent implements OnInit {
  currentUser_is_superuser: boolean = false;
  mode_of_travel_flight:boolean = false;
  mode_of_travel_train:boolean = true;
  accommodation: boolean = true;
  formId : string;
  base_api_url:any = environment.base_api_url;
  headline= 'Travel Requisition Form'
  isLoading:boolean = false
  trfmodel: TRFModel = new TRFModel();
  flightmodel: any = {};
  trainmodel: any = {};
  accomodation: any = {};
  remarks = new RemarksModel()
  travelcost =new TravelCost();
  loggedInUser : any;

  
  trfModel = new BehaviorSubject<TRFModel>(
    new TRFModel()
  );
  flightModel = new BehaviorSubject<FlightTicketModel>(
    new FlightTicketModel()
  );

  trainModel = new BehaviorSubject<TrainTicketModel>(
    new TrainTicketModel()
  );

  accomodationModel = new BehaviorSubject<AccomodationModel>(
    new AccomodationModel()
  );
  
  travelcostModel = new BehaviorSubject<TravelCost>(
    new TravelCost()
  );

  remarksModel = new BehaviorSubject<RemarksModel>(
    new RemarksModel()
  );

  submissionId: string | null = "";

  quillConfig={
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        // ['link', 'image', 'video']  
      ],
      
    },}

  constructor(private vm: FormDetailViewModel,private route: ActivatedRoute,private router: Router,
    private uiChangerSerice: UIChangerService
  ) {;
    this.submissionId = this.route.snapshot.paramMap.get('id');
    var url = window.location.href;
    this.formId = url.substring(url.lastIndexOf('/') + 1);
    const localStorage_user = MapCommon.getloggedInUser();
    this.loggedInUser = localStorage_user;
    this.currentUser_is_superuser = localStorage_user.is_superuser;
    if(localStorage_user.is_superuser==true){
      this.vm.getFormDetail(this.formId);
    }else{
      this.vm.getFormDetail(this.formId);

    }

  }

  ngOnInit(): void {
    this.vm.parentSubmission = this
    this.vm.subscribeDetailVariables(this.trfModel,this.flightModel,this.trainModel,this.accomodationModel,this.travelcostModel,this.remarksModel)
    this.uiChangerSerice.updateSubmissionPage(true);
  }

  ngOnDestroy(): void {
    this.uiChangerSerice.updateSubmissionPage(false);
  }



  onFileChange(event) {
    this.remarks.files = event.target.files;
  }

  detailRecive(){
    this.trfmodel = this.trfModel.value;
    this.flightmodel = this.flightModel.value;
    this.trainmodel = this.trainModel.value;
    this.accomodation = this.accomodationModel.value;
    if (this.travelcostModel.value){

      this.travelcost = this.travelcostModel.value;
    }
    if (this.remarksModel.value){
      this.remarks = this.remarksModel.value
    }

    // flight details hide/show
    if (this.flightModel.value.destination && this.flightModel.value.travelclass) {
      this.mode_of_travel_flight = true;
    } else {
      this.mode_of_travel_flight = false;
    }

    // train details hide/show
    if (this.trainModel.value.fromstation && this.trainModel.value.tostation) {
      this.mode_of_travel_train = true;
    } else {
      this.mode_of_travel_train = false;
    }

    // accommodation details hide/show
    if (this.accomodationModel.value.checkin && this.accomodationModel.value.checkout) {
      this.accommodation = true;
    } else {
      this.accommodation = false;
    }
  }



  markdone(){
    this.vm.uploadfile(this.formId,this.remarks)
  }
  addCost(){
    this.isLoading = true
    this.vm.performAction(this.formId,this.trfmodel, this.flightmodel, this.trainmodel, this.accomodation,this.travelcost);

  }

  markedFullfilled(){
    this.trfmodel.is_fullfilled = true;
    this.isLoading = false
    this.router.navigate(['/dashboard']);
  }

  // for user
  show_only_for_user() {
    if (this.currentUser_is_superuser) {
      return false;
    }
    return true;
  }

  // for admin
  show_only_for_admin() {
    if (this.currentUser_is_superuser) {
      return true;
    }
    return false;
  }

  
}
