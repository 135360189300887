<!-- <div class="card shadow-sm"> -->
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-between  border-bottom pb-2">
            <h6>

                <i class="fas fa-money-bill me-2 section-icon"></i> <span class="section-heading fw-bold">Cost Details</span> 
            </h6>
           

            <div class="col-6 d-flex justify-content-end">
                <div>
                    <a class="btn btn-sm collapse-btn shadow-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseEvent" aria-expanded="false" aria-controls="collapseCost">
                    <i class="fas fa-angle-down"> </i> 
                </a>
                </div>
             </div>

        </div>

        <div class="row collapse" id="collapseCost">

            <div class="col-12">
                <hr class="mt-0">
            </div>

            <div class="col-12">
                <select class="form-select form-control form-control-sm" aria-label="Default select example" name="currency" [disabled]="!is_approved" [(ngModel)]="parentCostData.currency">
                    <option selected value="INR">INR</option>
                    <option value="USD">USD</option>
                </select>
            </div>
            <div class="col-12 mt-3">
                <mdb-form-control class="">
                    <input mdbInput type="number" id="tr_date_of_journey" class="form-control form-control-sm" [disabled]="!is_approved" [(ngModel)]="parentCostData.totalcost"
                        name="totalcost" />
                    <label mdbLabel class="form-label" for="tr_date_of_journey">Total Cost</label>
                </mdb-form-control>
            </div>
            <div class="col-12 mt-3">
                <mdb-form-control class="">
                    <input mdbInput type="number" id="tr_train_name_or_number" class="form-control form-control-sm" [disabled]="!is_approved" [(ngModel)]="parentCostData.travelcost"
                        name="tavelcost" />
                    <label mdbLabel class="form-label" for="tr_train_name_or_number">Travel Cost</label>
                </mdb-form-control>
            </div>
            <div class="col-12 mt-3">
                <mdb-form-control class="">
                    <input mdbInput type="number" id="tr_from_station_name" class="form-control form-control-sm" [disabled]="!is_approved"
                        name="accommodationcost" [(ngModel)]="parentCostData.accomodationcost" />
                    <label mdbLabel class="form-label" for="tr_from_station_name">Accommodation
                        Cost</label>
                </mdb-form-control>
            </div>
        </div>

    </div>
<!-- </div> -->