<!-- Top bar -->
<!-- <app-topbar [headline]="headline" [tagline]="tagline"></app-topbar> -->

<!-- Page Heading -->
<!-- <h1 class="h3 mb-2 text-gray-800"></h1>

<p class="mb-4"></p> -->

<!-- DataTales Example -->
<div class="row">
    <div class="col mt-3">
        <div class="card shadow mb-4">
            <div class="card-header py-3  d-flex justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary mt-2">Submissions</h6>
                <span class="float-end small"> <a href="/travelform" class=" btn-sm btn-outline-primary" style="font-size:16px;color:#212529; font-weight:bold">Submit Travel Form</a></span>
            </div>
            <div class="card-body p-0 m-0" style="overflow: auto; height:70vh;white-space: nowrap;">
                <div class="table-responsive">
                    <table class="table table-bordered position-relative" id="dataTable" width="100%" cellspacing="0" >
                        <thead class="thead-dark">
                            <tr>
                                <th>Name</th>
                                <th>Position</th>
                                <th>Employee Id</th>
                                <th>Gender</th>
                                <th>Date Of Birth</th>
                                <th>Approver</th>
                            </tr>
                        </thead>
                        <!-- <tfoot>
                            <tr>
                                <th>Name</th>
                                <th>Position</th>
                                <th>EmployeeId</th>
                                <th>Gender</th>
                                <th>Date Of Birth</th>
                                <th>Approver</th>
                            </tr>
                        </tfoot> -->
                        <tbody *ngIf="all_forms">
                            <tr *ngFor="let form of all_forms">
                                <td>
                                    <a href="dashboard/submission/{{ form.id }}">{{ form.first_name }} {{ form.last_name }} </a>
                                </td>
                                <td>{{ form.designation }}</td>
                                <td>{{ form.employeeid }}</td>
                                <td>{{ form.gender == 'M' ? 'Male' :'Female' }}</td>
                                <td>{{ form.dob }}</td>
                                <td>{{ form.approver }}</td>
                            </tr>
                           
                           
                            
                           
                        </tbody>
                        <tbody *ngIf="!all_forms">
                            <tr>
                                <td colspan="6" style="text-align: center; font-weight: bold;"> No Data Found. </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<app-spinner *ngIf="isLoading"></app-spinner> 