import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MapCommon } from 'src/app/app-providers/trf-base/MapCommon';
import { CountModel, TRFResponseModel } from 'src/app/travel-requisition/models/travel-requisition-model';
import { FormsViewModel } from '../../models/form-view-model';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {
  approved: string = 'all';
  all_forms:any = [];
  data: any = {};
  count: any = {};
  isLoading:boolean = false
  countModel = new BehaviorSubject<CountModel>(new CountModel());
  headline='Dashboard'
  tagline='Travel Requisition Forms'
  trfResponseModel = new BehaviorSubject<TRFResponseModel>(
    new TRFResponseModel()
  );

  constructor(private vm: FormsViewModel, private router: Router) {

    
  }

  ngOnInit(): void {
    this.vm.parentForms = this;
    this.vm.subscribeDetail(this.trfResponseModel, this.countModel);
    this.count = this.vm.getcounts('cont');

    const localStorage_user = MapCommon.getloggedInUser();
    if (localStorage_user.is_superuser == true) {
      this.vm.getforms(this.approved , 'content');
    }else{
      this.vm.getforms('all', 'content','true');

    }
    this.isLoading = true
  }


  detailRecived() {
    this.isLoading = false
    this.all_forms = this.trfResponseModel.value.results;
  }

  countdetailRecived() {
    this.data = this.countModel.value;
  }



  getData(event) {
    const localStorage_user = MapCommon.getloggedInUser();
    this.isLoading = true
    if (localStorage_user.is_superuser == true) {
      this.vm.getforms(event.target.value , 'content');
    }else{
      this.vm.getforms(event.target.value, 'content','true');

    }
  }

 

  
}
