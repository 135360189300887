<div class="d-flex align-items-center justify-content-between pb-2">
   
     <span class="section-heading  fw-bold" style="font-size: 17px;"> <i class="fas fa-hotel me-2 section-icon"></i> Accommodation Details</span>

    <div class="col-6 d-flex justify-content-end">
        <div>
            <a class="btn btn-sm collapse-btn shadow-none" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseAccommodation" aria-expanded="false" aria-controls="collapseAccommodation">
            <i class="fas fa-angle-down"> </i> 
            </a>
        </div>
     </div>

</div>
<div class="collapse mt-2" id="collapseAccommodation">

    <div class="row">
        <div class="col-12">
            <hr class="mt-0">
        </div>
    </div>

    <ul class="p-0">
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Location
            </div>
            <div class="w-60 text-capitalize">
                : {{parentAccDetail.location ? parentAccDetail.location : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Occupancy
            </div>
            <div class="w-60 text-capitalize">
                : {{parentAccDetail.occupancy ? parentAccDetail.occupancy : 'NA' }}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Check In
            </div>
            <div class="w-60 text-capitalize">
                : {{parentAccDetail.checkin ? (parentAccDetail.checkin | date : "MMMM d, yyyy") : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Check Out
            </div>
            <div class="w-60 text-capitalize">
                : {{parentAccDetail.checkout ? (parentAccDetail.checkout | date : "MMMM d, yyyy") : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Prefered Location
            </div>
            <div class="w-60 text-capitalize">
                : {{parentAccDetail.preferedlocation ? parentAccDetail.preferedlocation : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Meal Preference
            </div>
            <div class="w-60 text-capitalize">
                : {{parentAccDetail.meal_preference_display ? parentAccDetail.meal_preference_display : 'NA'}}
            </div>
        </li>
        <li class="d-flex justify-content-between">
            <div class="w-40 mb-0 text-info fw-bold">
                Dietary Restriction
            </div>
            <div class="w-60 text-capitalize">
                : {{parentAccDetail.dietary_restriction ? parentAccDetail.dietary_restriction : 'NA'}}
            </div>
        </li>
    </ul>
</div>