import { Router } from "@angular/router";
import { LoginUserModel } from "src/app/login/models/login-model";
import { environment } from "src/environments/environment";

export class MapCommon {
  static mainRouter: Router;
  constructor() { }

  // to get logged in saved objects
  static getloggedInUser() {
    return JSON.parse(localStorage.getItem("loggedInUser")) || undefined;
  }

  static getBaseApiUrl(){
    return environment.base_api_url;
  }

  static getDefaultProspectGroup() {
    return 1;
  }
  // to get status of logged in user
  static isLoggedIn() {
    if (
      this.getloggedInUser() != undefined &&
      this.getloggedInUser() != null &&
      this.getloggedInUser().token != undefined &&
      this.getloggedInUser().token != ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  // to remove all local storage elements of the user
  static removeItemsOnLogOut() {
    //localStorage.removeItem('loggedInUser');
    localStorage.clear();
  }

  // to add logged in object recieved from login api
  static setloggedInUser(response: LoginUserModel) {
    try {
      if (response.id){
        localStorage.setItem("loggedInUser", JSON.stringify(response));
      }
    } catch {
      // Pass
    }
  }








}
